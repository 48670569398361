import { useState, useEffect } from "react";
import { apiOrchestrator } from "services/apiService";
import { useUser } from "global/UserContext";
import { useToast } from "hooks/useToast";

interface SocialNetwork {
  id?: number;
  social_network_type: string;
  social_network_username: string;
}

export const useSocialNetworks = () => {
  const { user } = useUser();
  const { toastError, toastSuccess } = useToast();
  const [loading, setLoading] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState<SocialNetwork[]>([]);
  const [networkTypes, setNetworkTypes] = useState<string[]>([]);

  const fetchNetworkTypes = async () => {
    try {
      const { data } = await apiOrchestrator.get(
        "/orchestrator-policy-role/v2/user-social-networks/types",
      );
      setNetworkTypes(data);
    } catch (error) {
      toastError("Erro ao buscar tipos de redes sociais.");
    }
  };

  const fetchSocialNetworks = async () => {
    if (!user?.id) return;
    setLoading(true);
    try {
      const { data } = await apiOrchestrator.get(
        `/orchestrator-policy-role/v2/user-social-networks/?user_id=${user.id}`,
      );
      setSocialNetworks(data);
    } catch (error) {
      toastError("Erro ao buscar redes sociais.");
    } finally {
      setLoading(false);
    }
  };

  const getUsernameByType = (type: string): SocialNetwork | undefined => {
    return socialNetworks.find((net) => net.social_network_type === type);
  };

  const upsertSocialNetwork = async (socialNetwork: SocialNetwork) => {
    if (!user?.id) return;
    const existingNetwork = socialNetworks.find(
      (net) => net.social_network_type === socialNetwork.social_network_type,
    );
    try {
      if (existingNetwork) {
        await apiOrchestrator.patch(
          `/orchestrator-policy-role/v2/user-social-networks/${user.id}`,
          {
            id: existingNetwork.id,
            ...socialNetwork,
          },
        );
        setSocialNetworks((prev) =>
          prev.map((net) =>
            net.social_network_type === socialNetwork.social_network_type
              ? { ...net, ...socialNetwork }
              : net,
          ),
        );
      } else {
        const { data } = await apiOrchestrator.post(
          `/orchestrator-policy-role/v2/user-social-networks/${user.id}`,
          socialNetwork,
        );
        setSocialNetworks((prev) => [...prev, data]);
      }
      toastSuccess("Rede social atualizada com sucesso!");
    } catch (error) {
      toastError("Erro ao atualizar rede social.");
    }
  };

  const deleteSocialNetwork = async (networkId: number) => {
    if (!user?.id || !networkId) return;
    try {
      await apiOrchestrator.delete(
        `/orchestrator-policy-role/v2/user-social-networks/${user.id}/${networkId}`,
      );
      setSocialNetworks((prev) => prev.filter((net) => net.id !== networkId));
      toastSuccess("Rede social removida com sucesso!");
    } catch (error) {
      toastError("Erro ao remover rede social.");
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchNetworkTypes();
      fetchSocialNetworks();
    }
  }, [user]);

  return {
    socialNetworks,
    networkTypes,
    loading,
    upsertSocialNetwork,
    deleteSocialNetwork,
    getUsernameByType,
  };
};
