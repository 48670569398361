import React, { useEffect, useState, useCallback } from "react";
import {
    FormControl,
    TextField,
} from "@mui/material";
import { Button } from "antd";
import Text from "componentsNew/Text";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";
import { RiPencilFill } from "react-icons/ri";
import { useDebounce } from "hooks/useDebounce";
import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import { useUser } from "global/UserContext";

interface TextFormFieldProps {
    field: I.TextField | I.TextAreaField;
}

const TextFormField: React.FC<TextFormFieldProps> = ({ field }) => {
    const { user } = useUser();
    const [text, setText] = useState<string>("");

    const [isTextareaReadOnly, setIsTextareaReadOnly] = useState<boolean>(true);

    const debouncedText = useDebounce(text, 1000);

    const [prevLoading, setPrevLoading] = useState<boolean>(true);
    const [prevError, setPrevError] = useState<string | null>(null);

    const { submitForm, loading, error } = useSubmitForm();

    const textLimit = field.field_type === "textarea" ? 800 : 100
    const user_id = user?.id;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await fetchFieldSavedData(field.id, user_id);

            if (data && data.length > 0) {
                const savedSubmission = data[0];
                const savedValues = savedSubmission?.values || [];

                if (Array.isArray(savedValues)) {
                    const savedValue = savedValues[0];
                    setText(savedValue.value || "")
                }
            }
        } catch (error) {
            setPrevError("Error fetching saved data.");
            console.error(error);
        } finally {
            setPrevLoading(false);
        }
    };

    // Handle form submission with debounced data
    async function submitData() {
        if (debouncedText.length <= textLimit) {
            try {
                await submitForm(field.id, { values: [{ "value": text }] });
            } catch (error) {
                console.error("Error submitting data:", error);
            }
        } else {
            console.error("Text exceeds the allowed limit");
        }
    };

    useEffect(() => {
        submitData();
    }, [debouncedText]);


    // Render loading state or main content
    if (prevLoading) {
        return <div>Loading...</div>;
    }

    // Handle error if needed
    if (prevError) {
        return <div>Error loading data</div>;
    }

    return (
        <S.Card key={field.id}>
            <Text size="medium" bold>
                {field.label}
            </Text>
            <FormControl fullWidth>
                <TextField
                    required={field.is_required}
                    placeholder={field.extras?.placeholders?.[0] || ""}
                    value={text}
                    onChange={(e) =>
                        setText(e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    error={text.length > textLimit}
                    multiline={field.field_type === "textarea"}
                    rows={7}
                    style={{ marginTop: "12px" }}
                    InputProps={{
                        readOnly: isTextareaReadOnly,
                    }}
                />
                <div style={{
                    "width": "100%",
                    "display": "flex",
                    "placeContent": "space-between",
                    "placeItems": "center"
                }}>
                    <Text size="small" color={text.length > textLimit ? "red" : "gray"}>
                        {text.length}/{textLimit}
                    </Text>
                    {isTextareaReadOnly && (
                        <Button
                            icon={<RiPencilFill />}
                            type="link"
                            size="small"
                            style={S.ButtonEditStyle}
                            onClick={() =>
                                setIsTextareaReadOnly(!isTextareaReadOnly)
                            }
                        >
                            Editar
                        </Button>
                    )}
                </div>
            </FormControl>
        </S.Card>
    );
};

export default TextFormField;
