import React, { useEffect, useState } from "react";

import { useUser } from "global/UserContext";
import { Container, Stack } from "@mui/material";
import Text from "componentsNew/Text";
import Spin from "componentsNew/Spin";
import InviteList from "./InviteList";
import { useInvites } from "../useInvitesHook";

import theme from "styles/theme"
import ModalNovaIndicacao from "./NovaIndicacaoModal";
import InternalButton from "componentsNew/InternalButton";
import AddIcon from '@mui/icons-material/Add';

const initialFormValues = {
  invitee_first_name: "",
  invitee_last_name: "",
  invitee_cell_phone: "",
  invitee_email: "",
  invite_status: "pending",
};

const InvitesPage: React.FC = () => {
  const { user } = useUser();
  const userId = user?.id;

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10); // default size
  const [orderBy, setOrderBy] = useState<string>("invited_at"); // Default order
  const [isDesc, setIsDesc] = useState<boolean>(true); // Default order
  const inviteStatus = ""; // Set to desired invite status or leave empty for all

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>(initialFormValues)

  const { invites, total, loading, error, fetchInvites, deleteInvite } =
    useInvites({ userId, page, size, orderBy, isDesc, inviteStatus });

  useEffect(() => {
    fetchInvites(userId);
  }, [page, size, orderBy, isDesc, inviteStatus]);

  function onPageChange(newPage: number) {
    setPage(newPage);
  }
  function onRowsPerPageChange(newRowsPerPage: number) {
    setSize(newRowsPerPage);
    setPage(0); // Reset to the first page when changing rows per page
  }

  function onClickEdit(invite: any) {
    console.log("onClickEdit", { invite });
    setFormValues({
      id: invite.id,
      invitee_first_name: invite.invitee_first_name,
      invitee_last_name: invite.invitee_last_name,
      invitee_cell_phone: invite.invitee_cell_phone,
      invitee_email: invite.invitee_email,
      invite_status: invite.invite_status,
    })
    handleOpenModal()
  }

  function onClickOrderBy(column: string) {
    setOrderBy(column)
  }

  function onClickDelete(inviteId: string) {
    deleteInvite(inviteId);
  }

  const handleClickNewInvite = () => {
    setFormValues(initialFormValues)
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    fetchInvites(userId);
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <>
        <Text color="primary" bold>
          Carregando convites...
        </Text>
        <Spin fullscreen />
      </>
    );
  }

  if (error) {
    return (
      <Text color="red" bold>
        Erro ao carregar convites: {error}
      </Text>
    );
  }

  return (
    <Stack spacing={1}>
      <InternalButton
        background={`${theme.COLORS.PRIMARY}`}
        icon={<AddIcon fontSize="small" />}
        style={{ alignSelf: "end", marginRight: "24px" }}
        variant="outline"
        onClick={handleClickNewInvite}
      >
        Novo Convite
      </InternalButton>
      <Container>
        <InviteList
          invites={invites}
          totalInvites={total}
          page={page}
          rowsPerPage={size}
          sortColumn={orderBy}
          isDesc={isDesc}
          setIsDesc={setIsDesc}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickOrderBy={onClickOrderBy}
        />
      </Container>

      {isModalOpen && (
        <ModalNovaIndicacao
          handleCloseModal={handleCloseModal}
          initialFormValues={formValues} />
      )}
    </Stack>
  );
};

export default InvitesPage;
