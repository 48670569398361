import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { Button } from "antd";
import { HiMiniPlus } from "react-icons/hi2";

import Text from "componentsNew/Text";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";

import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useUser } from "global/UserContext";

const TagField: React.FC<I.BaseField> = ({
  form_id,
  label,
  is_required,
  id,
  field_type,
  description,
  extras,
}) => {
  const { user } = useUser();
  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<Record<string, Record<number, string>>>(
    {},
  );
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [isMultipleTags, setIsMultipleTags] = useState<boolean>(false);

  const [prevLoading, setPrevLoading] = useState<boolean>(true);
  const [prevError, setPrevError] = useState<string | null>(null);

  const {
    submitForm,
    loading: submissonLoading,
    error: submissionError,
  } = useSubmitForm();
  const user_id = user?.id;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await fetchFieldSavedData(id, user_id);

    if (data && data.length > 0) {
      const savedSubmission = data[0];

      if (savedSubmission) {
        const savedTags = savedSubmission.values
          ?.map((value) => value.value) // Extract values
          .filter((value): value is string => value !== undefined); // Filter out undefined

        if (Array.isArray(savedTags)) {
          setTags(savedTags);
          console.log("Setting tags to:", savedTags);
          setPrevLoading(false);
        } else {
          console.error("Unexpected data structure for savedTags", savedTags);
        }
      }
    }
    setPrevLoading(false);
  };

  // console.log('handleAddNewTag', { savedData })
  useEffect(() => {
    const phold = extras?.placeholders || [];
    // Set placeholders and check if multiple tags should be allowed
    setPlaceholders(phold);
    setIsMultipleTags(phold.length > 0);
  }, [tags]);

  const handleTagChange = (fieldId: string, index: number, value: string) => {
    setNewTag((prev: any) => ({
      ...prev,
      [fieldId]: {
        ...(prev[fieldId] || {}),
        [index]: value, // Armazena o valor para o índice específico do placeholder
      },
    }));
  };

  const handleAddNewTag = async () => {
    let allTags = [...tags];

    if (isMultipleTags) {
      const newValue = Object.values(newTag[id]).join(" | ");
      allTags = [...allTags, newValue];
    } else {
      const newValues = Object.values(newTag[id])[0]; // Extract the first value from newTag[id]
      const splitValues = newValues
        ? newValues
            .split(",")
            .map((val) => val.trim())
            .filter((val) => val !== "")
        : [];
      allTags = [...allTags, ...splitValues];
    }
    const submissionData = {
      values: allTags.map((t) => ({ value: t })), // Map to expected format
    };
    await submitForm(id, submissionData);
    setTags(allTags);
  };

  const handleDelete = async (tagToDelete: string) => {
    // Filter out the tag that needs to be deleted
    const updatedTags = tags.filter((tag) => tag !== tagToDelete);

    // Prepare the submission data
    const submissionData = {
      values: updatedTags.map((t) => ({ value: t })), // Map to expected format
    };

    // Submit the updated tags
    await submitForm(id, submissionData);

    // Update the state with the new tags
    setTags(updatedTags);
  };

  // Render loading state or main content
  if (prevLoading) {
    return <div>Loading...</div>;
  }

  // Handle error if needed
  if (prevError) {
    return <div>Error loading data</div>; // or handle error appropriately
  }

  return (
    <S.GridContainer isTagsLayout={true}>
      <S.CardTags key={id}>
        <div>
          <Text size="medium" bold>
            {label}
          </Text>
          {description}
        </div>
        <FormControl fullWidth>
          <S.TagsContainer>
            {isMultipleTags ? (
              placeholders.map((placeholder, index) => (
                <TextField
                  fullWidth
                  key={index}
                  label={placeholder}
                  value={newTag[id]?.[index] || ""} // Referencia o valor específico do índice
                  onChange={(e) => handleTagChange(id, index, e.target.value)}
                  variant="outlined"
                  style={{ marginBottom: "8px", width: "48%" }}
                />
              ))
            ) : (
              <TextField
                fullWidth
                placeholder="Digite aqui..."
                variant="standard"
                value={newTag[id]?.[-1] || ""}
                onChange={(e) => handleTagChange(id, -1, e.target.value)}
                style={{ marginBottom: "8px" }}
              />
            )}

            <>
              <Button
                icon={<HiMiniPlus size={9} />}
                type="link"
                size="small"
                style={S.ButtonPlusStyle}
                onClick={handleAddNewTag}
              >
                Adicionar
              </Button>
            </>
          </S.TagsContainer>

          <S.TagsChipsContainer>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              {submissonLoading ? (
                <div> Salvando...</div>
              ) : (
                tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={() => handleDelete(tag)}
                  />
                ))
              )}
            </Stack>
          </S.TagsChipsContainer>
        </FormControl>
      </S.CardTags>
    </S.GridContainer>
  );
};

export default TagField;
