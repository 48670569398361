import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import ImageMain from "assets/images/login/logo-rainmakers.png";
import { ToastContainer, toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Modal } from "antd";
import PhoneInput from "react-phone-input-2";
import Button from "componentsNew/Button";
import Text from "componentsNew/Text";
import useRegister from "./useRegister";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import theme from "styles/theme";
import * as S from "./styles";

const Register: React.FC = () => {
  const { control, handleSubmit, errors, onSubmit, loading } = useRegister();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleModalClose = () => {
    setOpenModal(false);
    if (isSuccess) {
      navigate("/login");
    }
  };

  const handleFormSubmit = async (data: any) => {
    try {
      await onSubmit(data);
      setIsSuccess(true);
      toast.success("Cadastro realizado com sucesso!");
      setOpenModal(true);
    } catch (error) {
      setIsSuccess(false);
      toast.error(
        "Erro ao realizar o cadastro. Verifique os dados e tente novamente.",
      );
      setOpenModal(true);
    }
  };

  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Card>
            <S.Image src={ImageMain} />
          </S.Card>
          <S.Form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="form-container"
          >
            <h2>Registre-se em nossa plataforma!</h2>

            <S.ContentForm>
              <S.NameSurnameContainer>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nome"
                      variant="outlined"
                      fullWidth
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Sobrenome"
                      variant="outlined"
                      fullWidth
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </S.NameSurnameContainer>

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country={"br"}
                    preferredCountries={["br", "us", "gb"]}
                    inputStyle={{
                      width: "100%",
                      height: "56px",
                      backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
                      color: `${theme.COLORS.GRAY_10}`,
                      border: `1px solid ${errors.phone ? theme.COLORS.RED_1 : theme.COLORS.GRAY_40}`,
                      borderRadius: "4px",
                      paddingLeft: "50px",
                    }}
                    buttonStyle={{
                      backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
                      border: `1px solid ${errors.phone ? theme.COLORS.RED_1 : theme.COLORS.GRAY_40}`,
                    }}
                    dropdownStyle={{
                      backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
                      color: `${theme.COLORS.TEXT_PRIMARY}`,
                    }}
                    placeholder="Digite seu WhatsApp"
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false,
                    }}
                    isValid={(value: string) =>
                      !!value.match(/^\+?[1-9]\d{1,14}$/)
                    }
                    specialLabel=""
                    inputClass={!!errors.phone ? "error" : undefined}
                  />
                )}
              />
              {errors.phone && (
                <span
                  style={{
                    color: `${theme.COLORS.RED_1}`,
                    fontSize: "10px",
                    marginLeft: "1rem",
                    marginTop: "-0.5rem",
                  }}
                >
                  {errors.phone.message}
                </span>
              )}

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Senha"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff
                                style={{
                                  color: errors.password
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            ) : (
                              <Visibility
                                style={{
                                  color: errors.password
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Confirme a Senha"
                    type={showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={toggleShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff
                                style={{
                                  color: errors.confirmPassword
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            ) : (
                              <Visibility
                                style={{
                                  color: errors.confirmPassword
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </S.ContentForm>

            <S.ButtonsContainer>
              <Button
                type="submit"
                variant="minimal"
                color="primary"
                size="medium"
                onClick={() => navigate("/login")}
              >
                Voltar
              </Button>

              <Button
                type="submit"
                variant="primary"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? "Carregando..." : "Registrar-se"}
              </Button>
            </S.ButtonsContainer>
          </S.Form>
        </S.Content>

        <ToastContainer position="top-right" autoClose={5000} />

        <Modal
          open={openModal}
          onCancel={handleModalClose}
          closable
          maskClosable
          footer={null}
          width={400}
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "4rem",
          }}
        >
          {isSuccess ? (
            <>
              <Text size="large" bold>
                CONFIRMAÇÃO
              </Text>
              <S.Line />
              <S.ModalContent>
                <Text size="xSmall">Cadastro realizado com sucesso!</Text>
                <Button
                  onClick={handleModalClose}
                  variant="primary"
                  color="primary"
                  className="modal-button"
                >
                  Voltar para a página de login
                </Button>
              </S.ModalContent>
            </>
          ) : (
            <>
              <Text size="large" bold>
                ERRO
              </Text>
              <S.Line />
              <S.ModalContent>
                <Text size="medium">
                  Erro ao realizar o cadastro. Verifique os dados e tente
                  novamente.
                </Text>
                <Button
                  onClick={handleModalClose}
                  variant="primary"
                  color="primary"
                  className="modal-button"
                >
                  Fechar
                </Button>
              </S.ModalContent>
            </>
          )}
        </Modal>
      </S.Container>
    </S.Background>
  );
};

export default Register;
