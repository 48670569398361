import styled from "styled-components";
import theme from "styles/theme";

export const LabelNews = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin: 0;
  height: 3rem;
  color: ${theme.COLORS.BACKGROUND_PRIMARY} !important;
  background: ${theme.COLORS.PRIMARY};
  line-height: 2rem !important;
  width: auto;
  margin-left: -0.75rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 500;
`;
