import { useEffect } from "react";
import { apiAdvisor } from "pages/SettingsProfile/api/apiService";
import usePlanType from "pages/SettingsProfile/hooks/usePlanType";
import { useUser } from "global/UserContext";

const usePingOnline = () => {
  const planType = usePlanType();
  const { user } = useUser();

  useEffect(() => {
    if (planType !== "Advisor") {
      console.log("User is not an Advisor, ping not started.");
      return;
    }

    let intervalId: NodeJS.Timeout | null = null;

    const sendPing = async (idAdvisor: string) => {
      try {
        await apiAdvisor.patch(`/advisor/v1/advisors/${idAdvisor}/ping-online`);
        console.log("Ping sent successfully to Advisor:", idAdvisor);
      } catch (error) {
        console.error("Failed to send ping:", error);
      }
    };

    const fetchAdvisorIdAndStartPing = async () => {
      if (user?.person_id) {
        try {
          const response = await apiAdvisor.get(
            `/advisor/v1/advisors/by-user-id/${user.person_id}`,
          );
          const idAdvisor = response.data.id;

          if (idAdvisor) {
            console.log("Advisor ID found:", idAdvisor);
            sendPing(idAdvisor);
            intervalId = setInterval(() => {
              sendPing(idAdvisor);
            }, 150000);
          } else {
            console.log("Advisor ID not found for user:", user.person_id);
          }
        } catch (error) {
          console.error("Failed to fetch advisor ID:", error);
        }
      }
    };

    fetchAdvisorIdAndStartPing();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        console.log("Ping interval cleared");
      }
    };
  }, [user, planType]);
};

export default usePingOnline;
