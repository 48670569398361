import { useNavigate } from "react-router-dom";
import notFoundVideo from "assets/notFound.png";
import Text from "componentsNew/Text";
import Button from "componentsNew/Button";
import { Container } from "./styles";

const Page404 = () => {
  const history = useNavigate();
  const backDashboard = () => {
    history("/dashboard/cockpit");
  };

  return (
    <Container>
      <div className="not-found-video">
        <img src={notFoundVideo} alt="animação de erro" />
      </div>
      <div className="text-box">
        <Text size="xlarge" bold>
          Página não encontrada
        </Text>
        <div>
          <Button onClick={backDashboard}>Voltar para Dashboard</Button>
        </div>
      </div>
    </Container>
  );
};

export default Page404;
