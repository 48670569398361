import React, { useState } from "react";
import Button from "componentsNew/Button";
import Text from "componentsNew/Text";
import { meetingRooms } from "utils/meetingRooms";
import { useUser } from "global/UserContext";
import * as process from "process";
import * as S from "./styles";

const baseURL = process.env.REACT_APP_URL_MEETING;

interface RoomProps {
  roomName?: string;
  tagMeeting: keyof typeof meetingRooms;
  position: number;
}

const MeetingRoom = ({ tagMeeting, position }: RoomProps) => {
  const { user } = useUser();
  const userId = user?.id || "";

  const meetingDetails = meetingRooms[tagMeeting];

  if (!meetingDetails) {
    return (
      <Text size="medium" color="red">
        Meeting room not found.
      </Text>
    );
  }

  const { meeting } = meetingDetails;
  const meetingNumber = meeting.split("/j/")[1].split("?")[0];
  const passWord = meeting.split("pwd=")[1].split(".")[0];
  const iframeUrl = `${baseURL}/meetings/room?user=${userId}&meeting=${meetingNumber}&pwd=${passWord}`;

  const openInNewWindow = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const left = screenWidth / 2 + 300;
    const top = (screenHeight - 400) / 2 + 400;

    const windowFeatures = `toolbar=no, menubar=no, width=1000, height=450, top=${top}, left=${left}`;

    console.log("Entering the meeting...");
    if (iframeUrl) {
      window.open(iframeUrl, "_blank", windowFeatures);
    }
  };

  return (
    <S.Wrapper>
      <S.Content>
        <Button
          type="submit"
          title="Entrar na reunião"
          size="small"
          variant="primary"
          onClick={openInNewWindow}
          style={{
            width: "108px",
            marginLeft: `${142.2 * position}px`,
            fontWeight: "bold",
            fontSize: "10px",
          }}
        >
          Entrar na reunião
        </Button>
      </S.Content>
    </S.Wrapper>
  );
};

export default MeetingRoom;
