import React from "react";
import { UnderConstruction } from "componentsNew/UnderConstruction";
import * as S from "./styles";

export const WizardElectoralProfile: React.FC = () => {
  return (
    <S.Wrapper>
      <UnderConstruction />
    </S.Wrapper>
  );
};
