import React from "react";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as ThemeProviderMUI } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import theme from "./styles/theme";
import GlobalStyles from "./styles/global";
import ProviderContext from "./context";
import { AuthRoutes } from "./routes/auth.routes";
import "react-toastify/dist/ReactToastify.css";
import MaterialTheme from "./styles/MaterialTheme";
import AntProvider from "./styles/stylesProviderAnt";

const App = () => {
  return (
    <BrowserRouter>
      <ProviderContext>
        <AntProvider>
          <ThemeProviderMUI theme={MaterialTheme}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <AuthRoutes />
            </ThemeProvider>
          </ThemeProviderMUI>
        </AntProvider>
        <ToastContainer theme="dark" transition={Zoom} />
      </ProviderContext>
    </BrowserRouter>
  );
};

export default App;
