import styled from "styled-components";

interface propsContainer {
  open: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  transition: padding-left 0.5s;
  overflow: hidden;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NewsContainer = styled.div`
  display: flex;
  margin-left: 8.2rem;
`;

export const UserCardContainer = styled.div`
  width: 35rem;
  display: flex;
  justify-content: start;
  align-items: start;
  margin-left: 8rem;
`;

export const Wrapper = styled.div<propsContainer>`
  width: 100%;
  overflow: auto;
  height: calc(100% - 56px);
  padding-left: ${({ open }) => (open ? "260px" : "160px")};
  padding-top: 16px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  display: flex;
  justify-content: center;
  position: relative;
  transition: padding-left 0.3s;
`;

export const Content = styled.div`
  max-width: 1115px;
  width: 100%;
  margin: 16px;

  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
`;
