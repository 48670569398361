import React, { useEffect, useState } from "react";
import { generateZoomSignature } from "componentsNew/ZoomMeeting/utils/zoomUtils"; // The utility you just created
import { IMeeting } from "componentsNew/ZoomMeeting/types/typesMeeting";
import MeetingStyles from "styles/zoom.module.css";

const MeetingContent: React.FC<IMeeting> = ({
  meetingNumber,
  role,
  sdkKey = "",
  sdkSecret = "",
  passWord,
  userName,
  userEmail,
  leaveUrl,
}) => {
  const [zoomClient, setZoomClient] = useState<any>(null);

  useEffect(() => {
    const loadZoom = async () => {
      const ZoomEmbed = await (
        await import("@zoom/meetingsdk/embedded")
      ).default;

      const client = ZoomEmbed.createClient();
      setZoomClient(client);

      const zoomAppRoot = document.getElementById("meetingSDKElement");

      if (zoomAppRoot) {
        client.init({
          language: "pt-PT",
          zoomAppRoot: zoomAppRoot,
          patchJsMedia: true,
          leaveOnPageUnload: true,
          customize: {
            video: {
              viewSizes: {
                default: {
                  height: 500,
                  width: 800,
                },
                ribbon: {
                  width: 800,
                  height: 500,
                },
              },
            },
          },
        });

        if (!meetingNumber || !passWord) {
          console.error("Missing required parameters.");
          return;
        }

        const { signature } = generateZoomSignature(
          meetingNumber,
          role,
          sdkKey,
          sdkSecret,
        );

        try {
          client.join({
            meetingNumber,
            signature,
            sdkKey,
            userName,
            password: passWord,
          });
        } catch (error) {
          console.error("Error joining the meeting", error);
        }
      } else {
        console.error("Element 'meetingSDKElement' not found.");
      }
    };

    loadZoom();
  }, [
    meetingNumber,
    role,
    sdkKey,
    sdkSecret,
    passWord,
    userName,
    userEmail,
    leaveUrl,
  ]);

  return (
    <div className={MeetingStyles.container}>
      <div
        className={MeetingStyles.meetingSDKElement}
        id="meetingSDKElement"
      ></div>
      <div className={MeetingStyles.content}></div>
    </div>
  );
};

export default MeetingContent;
