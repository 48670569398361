import { useState, useEffect } from "react";
import { apiVox } from "services/apiService";
import AvatarDefault from "assets/avatar-default.png";

const useUserImage = (userId: string | null) => {
  const [userImage, setUserImage] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUserImage = async (userId: string) => {
    try {
      setLoadingImage(true);
      const response = await apiVox.get(
        `/orchestrator-policy-role/v2/users-person/get-image/${userId}`,
        { responseType: "blob" },
      );
      const imageUrl = URL.createObjectURL(response.data);
      setUserImage(imageUrl);
    } catch (error) {
      console.error("Error fetching user image", error);
      setUserImage(AvatarDefault);
      setError("Erro ao buscar a imagem do usuário.");
    } finally {
      setLoadingImage(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserImage(userId);
    }
  }, [userId]);

  return {
    userImage,
    loadingImage,
    error,
  };
};

export default useUserImage;
