import React, { useEffect, useState, useCallback } from "react";
import {
    FormControl,
    FormControlLabel,
    Checkbox,
    TextField,
} from "@mui/material";
import { Button } from "antd";
import Text from "componentsNew/Text";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";
import theme from "styles/theme";
import { RiPencilFill } from "react-icons/ri";
import { useDebounce } from "hooks/useDebounce";
import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import { useUser } from "global/UserContext";

interface CheckboxFieldProps {
    field: I.CheckboxField;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ field }) => {
    const { user } = useUser();
    const [placeholders, setPlaceholders] = useState<string[]>([]);
    const [formData, setFormData] = useState<Record<string, string>>({});
    const [otherText, setOtherText] = useState<Record<string, string>>({});
    const [isOtherReadOnly, setIsOtherReadOnly] = useState<Record<string, boolean>>({});
    const debouncedOtherText = useDebounce(otherText, 2000);
    const [prevLoading, setPrevLoading] = useState<boolean>(true);
    const [prevError, setPrevError] = useState<string | null>(null);
    const { submitForm, loading: submissionLoading, error: submissionError } = useSubmitForm();

    const user_id = user?.id;

    // Fetch initial placeholders and saved data
    useEffect(() => {
        const phold = field.extras?.placeholders || [];
        setPlaceholders(phold);
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await fetchFieldSavedData(field.id, user_id);
            if (data && data.length > 0) {
                const savedSubmission = data[0];
                const savedValues = savedSubmission?.values || [];

                const otherOption = field.options.find((o) => o.option_label === 'Outro');

                savedValues.forEach((savedValue) => {
                    const optionId = savedValue?.option_id;
                    const value = savedValue?.value || "";
                    if (optionId) {
                        setFormData((prev) => ({ ...prev, [optionId]: value }));

                        // Handle "Outro" option
                        if (otherOption?.id === optionId) {
                            setOtherText((prev) => ({ ...prev, [optionId]: value }));
                        }
                    }
                });
            }
        } catch (error) {
            setPrevError("Error fetching saved data.");
            console.error(error);
        } finally {
            setPrevLoading(false);
        }
    };

    // Handle form submission with debounced data
    const submitData = useCallback(async () => {
        const submissionData: { values: { option_id: string; value: string }[] } = {
            values: Object.keys(formData).map((optionId) => ({
                option_id: optionId,
                value: formData[optionId],
            })),
        };
        try {
            await submitForm(field.id, submissionData);
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    }, [formData]);

    useEffect(() => {
        // if (Object.keys(debouncedOtherText).length > 0) {
        submitData();
        // }
    }, [debouncedOtherText, submitData]);

    // Handle checkbox changes
    const handleCheckboxChange = (
        optionId: string,
        optionLabel: string,
        checked: boolean,
    ) => {
        setFormData((prevData) => {
            const updatedData = { ...prevData };

            // Update formData based on checkbox state
            if (checked) {
                updatedData[optionId] = optionLabel;
            } else {
                delete updatedData[optionId];
            }

            return updatedData; // No submission here; only update the state
        });
    };


    const handleOtherCheckboxChange = (optionId: string, checked: boolean) => {
        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (checked) {
                updatedData[optionId] = "Outro";
                setIsOtherReadOnly((prevState) => ({ ...prevState, [optionId]: false }));
            } else {
                delete updatedData[optionId];
                setOtherText((prevText) => ({ ...prevText, [optionId]: "" }));
            }
            return updatedData;
        });
    };

    const handleOtherTextChange = (optionId: string, value: string) => {
        setOtherText((prev) => ({ ...prev, [optionId]: value }));
    };

    const handleBlur = (optionId: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [optionId]: otherText[optionId],
        }));
    };
    // Render loading state or main content
    if (prevLoading) {
        return <div>Loading...</div>;
    }

    // Handle error if needed
    if (prevError) {
        return <div>Error loading data</div>; // or handle error appropriately
    }

    return (
        <S.Card key={field.id}>
            <Text size="medium" bold>
                {field.label}
            </Text>
            {field.description}
            <FormControl component="fieldset" >
                {field.options.map((option) => {
                    const isOtherOption = option.option_label === "Outro";
                    return (
                        <S.StyledFormControlLabel key={option.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            color: theme.COLORS.PRIMARY,
                                            "&.Mui-checked": {
                                                color: theme.COLORS.PRIMARY,
                                            },
                                        }}

                                        checked={!!formData[option.id]}
                                        onChange={(e) => {
                                            const checked = (e.target as HTMLInputElement)
                                                .checked;

                                            if (isOtherOption) {
                                                handleOtherCheckboxChange(
                                                    option.id,
                                                    checked,
                                                );
                                            } else {
                                                handleCheckboxChange(
                                                    option.id,
                                                    option.option_label,
                                                    checked,
                                                );
                                            }
                                        }}
                                    />
                                }
                                label={option.option_label}

                                sx={{
                                    alignItems: "flex-start",
                                    marginTop:"8px"
                                }}
                            />

                            {isOtherOption && !!formData[option.id] && (
                                <S.Specify>
                                    <TextField
                                        placeholder="Especifique"
                                        value={otherText[option.id] || ""}
                                        onChange={(e) =>
                                            handleOtherTextChange(
                                                option.id,
                                                e.target.value,
                                            )
                                        }
                                        variant="standard"
                                        InputProps={{
                                            readOnly: isOtherReadOnly[option.id],
                                        }}
                                        onBlur={() => handleBlur(option.id)}
                                    />

                                    {isOtherReadOnly[option.id] && (
                                        <Button
                                            icon={<RiPencilFill />}
                                            type="link"
                                            size="small"
                                            style={S.ButtonEditStyle}
                                            onClick={() =>
                                                setIsOtherReadOnly((prevState) => ({
                                                    ...prevState,
                                                    [option.id]: false,
                                                }))
                                            }
                                        >
                                            Editar
                                        </Button>
                                    )}
                                </S.Specify>
                            )}
                        </S.StyledFormControlLabel>
                    );
                })}
            </FormControl>
        </S.Card>
    );
};

export default CheckboxField;
