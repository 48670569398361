export const meetingRooms = {
  plenaryRoom: {
    meeting:
      "https://us05web.zoom.us/j/82329130294?pwd=pyJjMGHow4OTJ2HPo5eQXT2QiVeTEM.1",
  },
  personalDevelopment: {
    meeting:
      "https://us05web.zoom.us/j/89430729632?pwd=2So4ARP8RO1q6ZK5a9i13vgL3RHHNH.1",
  },
  salesAndExpansion: {
    meeting:
      "https://us05web.zoom.us/j/87161519765?pwd=uMkUMPxbZwwGNTPJpqXHMeJVLmu7aY.1",
  },
  crm: {
    meeting:
      "https://us05web.zoom.us/j/87359803722?pwd=aEcwmpfyxTY7xZza9od54nSrBCBa6y.1",
  },
  technology: {
    meeting:
      "https://us05web.zoom.us/j/85918856709?pwd=qua21GuHC1fHtKL6nu0q7e3skmd50A.1",
  },
  peopleManagement: {
    meeting:
      "https://us05web.zoom.us/j/89410583251?pwd=flOnHCJSo59I5GclIekhLxM5h1Ja4H.1",
  },
  cLevel: {
    meeting:
      "https://us05web.zoom.us/j/87894564010?pwd=uGE7L6WMdBvSYvBY3GN5gnpOgYo34i.1",
  },
};
