import React, { useState } from "react";
import { Button, Progress } from "antd";
import { IoEye } from "react-icons/io5";
import { useUser } from "global/UserContext";
import Text from "componentsNew/Text";
import theme from "styles/theme";
import * as S from "../styles";
import InternalButton from "componentsNew/InternalButton";

const SelfAssessmentsComponents: React.FC = () => {
  const { user } = useUser();
  const progress = 82;
  const profileLevel = "OURO";

  return (
    <S.AssessmentPropsContainer>
      <S.LevelContainer>
        <S.LevelInfo>
          <Text size="xSmall">
            Sua conta é nível <strong>{profileLevel}</strong>
          </Text>
          <Text>{progress}%</Text>
        </S.LevelInfo>
        <Progress
          percent={progress}
          showInfo={false}
          strokeColor={theme.COLORS.PRIMARY}
          trailColor={theme.COLORS.GRAY_80}
        />

        <InternalButton
          disabled
          background={`${theme.COLORS.PRIMARY}`}
          icon={<IoEye />}
          variant="outline"
        >
          Ver nível
        </InternalButton>
      </S.LevelContainer>
      <S.LevelContainer>
        <Button
          disabled
          type="default"
          size="large"
          block
          style={S.ButtonStyle}
        >
          Pre-visualizar
        </Button>
        <Button
          disabled
          type="primary"
          size="large"
          block
          style={S.PublishButtonStyle}
        >
          Publicar
        </Button>
      </S.LevelContainer>
    </S.AssessmentPropsContainer>
  );
};

export default SelfAssessmentsComponents;
