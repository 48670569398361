import React, { useState } from "react";
import { Modal, Button, Upload, Card, Avatar } from "antd";
import { useUser } from "global/UserContext";
import useProfileImage from "pages/SettingsProfile/hooks/useProfileImage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProfileImage: React.FC = () => {
  const { user } = useUser();
  const { profileImage, updateProfileImage, deleteProfileImage, loading } =
    useProfileImage(user?.person_id || "");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleUpload = (info: any) => {
    const file = info.file;

    if (!file) {
      toast.error("Nenhum arquivo foi selecionado.");
      return;
    }

    if (!file.type || !file.type.startsWith("image/")) {
      toast.error("Por favor, envie um arquivo de imagem válido.");
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result?.toString();
      if (base64) {
        setPreviewImage(base64);
      } else {
        toast.error("Erro ao converter a imagem. Tente novamente.");
      }
    };
    reader.readAsDataURL(file);
  };

  const handleConfirmUpload = () => {
    if (selectedFile) {
      updateProfileImage(selectedFile);
      setPreviewImage(null);
      setSelectedFile(null);
      toast.success("Foto de perfil atualizada com sucesso!");
    }
  };

  const handleCancelUpload = () => {
    setPreviewImage(null);
    setSelectedFile(null);
  };

  const handleDelete = () => {
    deleteProfileImage();
    toast.success("Foto de perfil removida com sucesso!");
    setIsModalVisible(false);
  };

  const getInitials = () => {
    const nameArray = user?.first_name?.split(" ") || [];
    const initials = nameArray.length
      ? `${nameArray[0][0]}${nameArray[nameArray.length - 1][0]}`
      : "U";
    return initials.toUpperCase();
  };

  const renderAvatarOrImage = () => {
    if (previewImage) {
      return (
        <>
          <p>Pré-visualização:</p>
          <img
            src={previewImage}
            alt="Preview"
            style={{ width: 150, height: 150, borderRadius: "50%" }}
          />
          <div style={{ marginTop: 16 }}>
            <Button
              onClick={handleCancelUpload}
              style={{
                width: "120px",
                margin: 8,
                color: "#FFC000",
                backgroundColor: "transparent",
                border: "1px solid #FFC000",
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleConfirmUpload}
              loading={loading}
              style={{
                width: "120px",
                margin: 8,
                color: "#000000",
                backgroundColor: "#FFC000",
                border: "none",
              }}
            >
              Confirmar envio
            </Button>
          </div>
        </>
      );
    }

    if (profileImage) {
      return (
        <img
          src={profileImage}
          alt="Profile"
          style={{ width: 150, height: 150, borderRadius: "50%" }}
        />
      );
    }

    return (
      <Avatar
        size={150}
        style={{
          color: "#EAEAE0",
          backgroundColor: "#0A0A0A",
          fontSize: 48,
          lineHeight: "150px",
        }}
      >
        {getInitials()}
      </Avatar>
    );
  };

  const renderUploadButton = () => (
    <Upload
      showUploadList={false}
      beforeUpload={() => false}
      onChange={handleUpload}
      accept=".jpg,.jpeg,.png,.gif,.bmp,.webp,.tiff,.svg"
    >
      <Button
        size="small"
        type="link"
        loading={loading}
        style={{
          color: "#FFC000",
          textDecoration: "underline",
        }}
      >
        Carregar nova foto de perfil
      </Button>
    </Upload>
  );

  const renderDeleteButton = () => (
    <Button
      danger
      type="link"
      style={{
        marginTop: 16,
        textDecoration: "underline",
      }}
      onClick={() => setIsModalVisible(true)}
    >
      Remover foto de perfil
    </Button>
  );

  return (
    <Card style={{ backgroundColor: "#161617", border: "none" }}>
      <ToastContainer position="top-right" autoClose={5000} />
      <p style={{ color: "#EAEAE0", fontWeight: "500", margin: "-12px -8px" }}>
        Foto de Perfil
      </p>
      <div style={{ textAlign: "center", marginBottom: "16px" }}>
        {renderAvatarOrImage()}

        <div style={{ marginTop: 16 }}>{renderUploadButton()}</div>

        {profileImage && !previewImage && renderDeleteButton()}
      </div>

      <Modal open={isModalVisible} footer={null} width={400}>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#EAEAE0",
            fontWeight: "600",
            fontSize: "24px",
            marginBottom: 8,
          }}
        >
          CONFIRMAÇÃO
        </p>
        <div
          style={{
            display: "flex",
            color: "#EAEAE0",
            borderBottom: "1px solid",
            marginBottom: "24px",
          }}
        ></div>
        <p style={{ color: "#EAEAE0" }}>
          Deseja realmente remover a Foto de Perfil?
        </p>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <Button
            onClick={() => setIsModalVisible(false)}
            style={{
              width: "140px",
              height: "48px",
              margin: 8,
              color: "#FFC000",
              backgroundColor: "transparent",
              border: "1px solid #FFC000",
            }}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleDelete}
            loading={loading}
            style={{
              width: "140px",
              height: "48px",
              margin: 8,
              color: "#000000",
              backgroundColor: "#FF0000",
              border: "none",
            }}
          >
            Remover
          </Button>
        </div>
      </Modal>
    </Card>
  );
};

export default ProfileImage;
