import { useState } from "react";
import { useCandidates } from "hooks/useCandidates";
import { IWordData } from "componentsNew/Wordcloud";
import { apiElectoralIntelligence } from "services/apiService";

const initialNetworks = ["Instagram", "YouTube"];

const useSocialMentionsWcCockpit = () => {
  const [selectedSN, setSelectedSN] = useState<string[]>(initialNetworks);
  const [wordsPositive, setWordsPositive] = useState<IWordData[]>([]);
  const [wordsNegative, setWordsNegative] = useState<IWordData[]>([]);
  const [wordsDefault, setWordsDefault] = useState<IWordData[]>([]);
  const [isLoadingPositive, setLoadingPositive] = useState(false);
  const [isLoadingNegative, setLoadingNegative] = useState(false);
  const [isLoadingDefault, setLoadingDefault] = useState(false);
  const { selectedCandidate } = useCandidates();
  const cand_id = selectedCandidate?.id_cand;

  const toggleSocialNetwork = (network: string) => {
    setSelectedSN((prevState) =>
      prevState.includes(network)
        ? prevState.filter((sn) => sn !== network)
        : [...prevState, network],
    );
  };

  const getWordData = async (
    sentiment: string,
    parentName: string,
    initialDate: string,
    endDate: string,
    city: string,
    idCandidate: string,
  ) => {
    setLoadingDefault(true);
    const data = await fetchDataWords({
      socialNetwork: selectedSN.join(","),
      sentiment: sentiment,
      candidate_id: cand_id,
    });
    setWordsDefault(data);
    setLoadingDefault(false);
  };

  const getWordPositive = async () => {
    setLoadingPositive(true);
    const data = await fetchDataWords({
      socialNetwork: selectedSN.join(","),
      sentiment: "positive",
      candidate_id: cand_id,
    });
    setWordsPositive(data);
    setLoadingPositive(false);
  };

  const getWordNegative = async () => {
    setLoadingNegative(true);
    const data = await fetchDataWords({
      socialNetwork: selectedSN.join(","),
      sentiment: "negative",
      candidate_id: cand_id,
    });
    setWordsNegative(data);
    setLoadingNegative(false);
  };

  async function fetchDataWords({
    socialNetwork,
    sentiment,
    parent_name,
    topic_name,
    mentions,
    topic_lvl,
    dateStart,
    endDate,
    city,
    candidate_id,
  }: any) {
    const queryParams = [];

    if (sentiment) {
      queryParams.push(`sentiment=${sentiment}`);
    }
    if (socialNetwork) {
      queryParams.push(`page_type_name=${socialNetwork}`);
    }
    if (dateStart) {
      queryParams.push(`date_start=${dateStart}`);
    }
    if (endDate) {
      queryParams.push(`date_end=${endDate}`);
    }
    if (city) {
      queryParams.push(`cd_city=${city}`);
    }
    if (candidate_id) {
      queryParams.push(`candidate_id=${candidate_id}`);
    }
    if (parent_name) {
      queryParams.push(`parent_name=${parent_name}`);
    }
    if (topic_name) {
      queryParams.push(`topic_name=${topic_name}`);
    }

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    try {
      const resp = await apiElectoralIntelligence.get(
        `/wordcloud${queryString}`,
      );
      return resp?.data.map((item: any) => ({
        text: item.word,
        value: item.value,
      }));
    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
      return [];
    }
  }

  return {
    wordsPositive,
    wordsNegative,
    wordsDefault,
    selectedSN,
    toggleSocialNetwork,
    getWordPositive,
    getWordNegative,
    getWordData,
    isLoadingPositive,
    isLoadingNegative,
    isLoadingDefault,
  };
};

export default useSocialMentionsWcCockpit;
