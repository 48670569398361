import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import AvatarDefault from "assets/avatar-default.png";
import Logo from "assets/icons/powered-by-algoricca.svg";
import Avatars from "components/Avatars";
import ModalSubMenu from "componentsNew/ModalSubMenu";
import Text from "componentsNew/Text";
import ButtonSibBar from "componentsNew/ButtonSidBar";
import { useSidBar } from "hooks/useSideBar";
import { useUser } from "global/UserContext";
import { useDrawerState } from "global/DrawerContext";
import { deleteItemStorage } from "utils/persistenceUtils";
import { AUTH_TOKEN } from "utils/constants";
import sidebarMenu from "components/SideBar/sideBarMenu";
import usePingOnline from "microfrontends/hooks/usePingOnline";
import useUserImage from "hooks/useUserImage";
import * as S from "./styles";

const SideBar = () => {
  const history = useNavigate();
  const { pathname } = useLocation();
  const { open, setOpen } = useSidBar();
  const { user } = useUser();
  const { setActivePlanFeatures } = useDrawerState();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(true);
  const [activeSubMenuTitle, setActiveSubMenuTitle] = useState<string | null>(
    null,
  );

  const { userImage, loadingImage } = useUserImage(user?.id || "");

  let selectedMenu = sidebarMenu;

  let planFeatures = [];

  try {
    const planObject = localStorage.getItem("planFeatures");
    planFeatures = planObject ? JSON.parse(planObject) : [];

    const userPlanNamesFeatures = planFeatures?.features_with_limits?.map(
      (features_with_limit: any) => {
        return features_with_limit.feature.name;
      },
    );
    const userPlanDescriptionFeatures = planFeatures?.features_with_limits?.map(
      (features_with_limit: any) => {
        return features_with_limit.feature.description;
      },
    );

    let featuresResult = sidebarMenu.filter(
      (menu) =>
        userPlanNamesFeatures.includes(menu.title) ||
        userPlanDescriptionFeatures.includes(menu.title),
    );

    for (let i = 0; i < featuresResult.length; i++) {
      let subMenu = featuresResult[i].subMenu;
      if (subMenu && subMenu.length > 0) {
        featuresResult[i].subMenu = subMenu.filter(
          (subMenu) =>
            userPlanNamesFeatures.includes(subMenu.title) ||
            userPlanDescriptionFeatures.includes(subMenu.title),
        );
      }
    }

    selectedMenu = featuresResult.length > 0 ? featuresResult : sidebarMenu;
  } catch (error) {
    console.error("Error on get plan features", error);
  }

  useEffect(() => {
    const matchingMenu = selectedMenu.find((menu) =>
      menu.subMenu?.some((sub) => pathname.startsWith(sub.path)),
    );
    if (matchingMenu && matchingMenu.title !== "Cockpit") {
      setActiveSubMenuTitle(matchingMenu.title);
      setIsSubMenuOpen(true);
    } else {
      setActiveSubMenuTitle(null);
      setIsSubMenuOpen(false);
    }
    setActivePlanFeatures(planFeatures);
  }, [pathname]);

  usePingOnline();

  const logout = () => {
    deleteItemStorage(AUTH_TOKEN);
    history("/login");
  };

  const memoizedCallback = useCallback(() => {
    return (
      <Avatars
        img={userImage || AvatarDefault}
        size={{ width: open ? 56 : 32, height: open ? 56 : 32 }}
      />
    );
  }, [userImage, open]);

  return (
    <S.Container open={open}>
      <S.WrapperMenu>
        {/*  <S.ButtonToggle open={open} onClick={() => setOpen(!open)}>
          {open ? <FaAngleLeft /> : <FaAngleRight />}
        </S.ButtonToggle> */}
        <div className="avatar">
          {memoizedCallback()}
          {open && (
            <div style={{ marginTop: "0.5rem" }}>
              <Text size="small" bold className="user-name">
                Bem vindo,
              </Text>
              <Text size="small" className="user-name">
                {user?.first_name} {user?.last_name}
              </Text>
            </div>
          )}
        </div>

        {selectedMenu.map((menu: any) => {
          return (
            <ButtonSibBar
              label={menu?.label}
              path={menu.path}
              title={menu.title}
              subtitle={menu.subtitle}
              icon={menu.icon}
              isActive={menu.isActive}
              subMenu={menu.subMenu}
              key={menu.title}
            />
          );
        })}
      </S.WrapperMenu>

      {isSubMenuOpen && activeSubMenuTitle && (
        <ModalSubMenu
          subMenu={
            selectedMenu.find((menu) => menu.title === activeSubMenuTitle)
              ?.subMenu || []
          }
          title={activeSubMenuTitle}
          closeSubMenu={() => setIsSubMenuOpen(false)}
        />
      )}

      <S.WrapperFooter>
        {open && (
          <>
            <S.Logo>
              <img src={Logo} alt="Logo Algoricca" />
            </S.Logo>

            <div className="plan">
              {user?.user_to_organization &&
              user?.user_to_organization.length > 0 ? (
                user?.user_to_organization.map((org) => (
                  <Text size="small" key={org.organization.uuid}>
                    Plano: <b>{org.organization?.plan?.name || "N/A"}</b>
                  </Text>
                ))
              ) : (
                <Text size="small">Nenhum plano associado</Text>
              )}
            </div>
          </>
        )}

        <button type="button" onClick={logout}>
          <Text bold color="primary" size="small">
            Sair
          </Text>
        </button>
      </S.WrapperFooter>
    </S.Container>
  );
};

export default SideBar;
