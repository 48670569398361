import React from "react";
import { useCandidates } from "hooks/useCandidates";
import Avatars from "componentsNew";
import Logo from "assets/images/login/logo-rainmakers.png";
import * as S from "./styles";

export const AvatarCandidateCard = () => {
  const { selectedCandidate, userDataCandidateSelected } = useCandidates();

  return (
    <S.Container>
      <S.Wrapper>
        <S.AvatarImageBox>
          <Avatars
            img={selectedCandidate?.image_url}
            size={{ width: "100%", height: "100%" }}
          />
        </S.AvatarImageBox>
        {
          <S.Logo>
            <img src={Logo} alt="Logo RainMakers" />
          </S.Logo>
        }
      </S.Wrapper>
    </S.Container>
  );
};
