import { ReactComponent as IconFacebook } from "assets/icon-facebook-listening.svg";
import { ReactComponent as IconInstagram } from "assets/icon-instagram.svg";
import { ReactComponent as IconYouTube } from "assets/icon-youtube.svg";
import { ReactComponent as IconLinkedIn } from "assets/icon-linkedin-listening.svg";
import { ReactComponent as IconReddit } from "assets/icon-reddit.svg";
import { ReactComponent as IconTT } from "assets/icon-x-listening.svg";
import { ReactComponent as IconGoogle } from "assets/iconGoogle.svg";
import { ReactComponent as IconTelegram } from "assets/icon-telegram.svg";

import { ReactComponent as DevicesIcon } from "assets/devices.svg";
import { ReactComponent as InfraIcon } from "assets/antena.svg";
import { ReactComponent as IotIcon } from "assets/iot.svg";
import { ReactComponent as TumblerIcon } from "assets/tumbler.svg";
import { ReactComponent as NetworkIcon } from "assets/network.svg";

export {
  IconFacebook,
  IconInstagram,
  IconYouTube,
  IconLinkedIn,
  IconReddit,
  IconTT,
  IconGoogle,
  IconTelegram,
  DevicesIcon,
  InfraIcon,
  IotIcon,
  TumblerIcon,
  NetworkIcon,
};
