import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Stack,
    Pagination,
    Tooltip,
} from '@mui/material';

import { ReactComponent as PendingStatusIcon } from "assets/icons/status/pending-status.svg"
import { ReactComponent as AcceptedStatusIcon } from "assets/icons/status/accepted-status.svg"
import { ReactComponent as DeclinedStatusIcon } from "assets/icons/status/declined-status.svg"
import { ReactComponent as DeleteIcon } from "assets/icons/actions/delete.svg"
import { ReactComponent as EditIcon } from "assets/icons/actions/edit.svg"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import * as S from "./styles"

interface Invite {
    id: string;
    user_id: string;
    invitee_first_name: string;
    invitee_last_name: string;
    invitee_email: string;
    invitee_cell_phone: string;
    invite_status: 'pending' | 'accepted' | 'declined';
    invited_at: string;
    updated_at: string;
}

interface InvitesListProps {
    invites: Invite[];
    totalInvites: number;
    page: number;
    rowsPerPage: number;
    sortColumn: string;
    isDesc: boolean;
    setIsDesc: any;
    onPageChange: (newPage: number) => void;
    onRowsPerPageChange: (newRowsPerPage: number) => void;
    onClickEdit: (invite: Invite) => void
    onClickDelete: (inviteId: string) => void
    onClickOrderBy: (column: string) => void
}

const InvitesList: React.FC<InvitesListProps> = ({
    invites,
    totalInvites,
    page,
    rowsPerPage,
    sortColumn,
    isDesc,
    onPageChange,
    onRowsPerPageChange,
    onClickEdit,
    onClickOrderBy,
    setIsDesc,
    onClickDelete
}) => {

    useEffect(() => {
    }, [invites])

    function hanglePageChange(event: React.ChangeEvent<unknown>, value: number) {
        onPageChange(value - 1)
    }

    function getTotalPages() {
        if (rowsPerPage <= 0) {
            throw new Error("Page size must be greater than zero");
        }
        return Math.ceil(totalInvites / rowsPerPage);
    }

    const handleHeaderClick = (key: string) => {
        if (sortColumn === key) {
            setIsDesc(!isDesc);
        } else {
            onClickOrderBy(key);
            setIsDesc(true);
        }
    };

    const headers = [
        { key: "invite_status", label: "Status", sx: { borderRadius: "8px 0 0 8px" } },
        { key: "invitee_first_name", label: "Nome" },
        { key: "invited_at", label: "Data" },
        { key: "invitee_cell_phone", label: "Telefone" },
        { key: "invitee_email", label: "Email" },
        { key: "actions", label: "Ações", sx: { borderRadius: "0 8px 8px 0" } },
    ]

    return (
        <S.InviteListContainer>
            <TableContainer>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {headers.map((h) => (
                                <TableCell
                                    key={h.key}
                                    sx={h.sx ? { ...h.sx } : undefined}
                                    onClick={() => handleHeaderClick(h.key)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Stack spacing={1} direction={"row"}>
                                        <span>
                                            {h.label}
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {sortColumn === h.key && (
                                                isDesc ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />
                                            )}
                                        </div>
                                    </Stack>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invites.map((invite) => (
                            <TableRow key={invite.id}>
                                <TableCell sx={{ borderRadius: "8px 0 0 8px" }}>
                                    <Tooltip title={getInviteStatusTooltipLabel(invite.invite_status)} placement="right">
                                        {getInviteStatusIcon(invite.invite_status)}
                                    </Tooltip>
                                </TableCell>
                                <TableCell >
                                    {invite.invitee_first_name} {invite.invitee_last_name}
                                </TableCell>
                                <TableCell>
                                    {new Date(invite.invited_at).toLocaleDateString('pt-BR')}
                                </TableCell>
                                <TableCell>{invite.invitee_cell_phone}</TableCell>
                                <TableCell>{invite.invitee_email}</TableCell>
                                <TableCell sx={{ borderRadius: "0 8px 8px 0" }}>
                                    <Stack direction="row">
                                        <Tooltip title="Editar convite" placement='top'>
                                            <IconButton onClick={() => onClickEdit(invite)} sx={{ padding: "4px" }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Deletar convite" placement='top'>
                                            <IconButton onClick={() => onClickDelete(invite.id)} sx={{ padding: "4px" }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/* <TablePagination
                    rowsPerPageOptions={[1, 5, 10, 25]}
                    component="div"
                    count={totalInvites}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => onPageChange(newPage)}
                    onRowsPerPageChange={(event) => {
                        onRowsPerPageChange(parseInt(event.target.value, 10));
                    }}
                /> */}
                <Pagination
                    count={getTotalPages()}
                    page={page + 1}
                    onChange={hanglePageChange}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "8px"
                    }}
                />
            </TableContainer >
        </S.InviteListContainer>
    );
};

function getInviteStatusIcon(status: 'pending' | 'accepted' | 'declined'): JSX.Element {
    switch (status) {
        case 'pending':
            return <PendingStatusIcon />;
        case 'accepted':
            return <AcceptedStatusIcon />;
        case 'declined':
            return <DeclinedStatusIcon />;
        default:
            return <PendingStatusIcon />;
    }
}

function getInviteStatusTooltipLabel(status: 'pending' | 'accepted' | 'declined'): string {
    switch (status) {
        case 'pending':
            return "Pendente";
        case 'accepted':
            return "Aceito";
        case 'declined':
            return "Recusado";
        default:
            return "Pendente";
    }
}

export default InvitesList;