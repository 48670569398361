import styled from "styled-components";
import theme from "styles/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const PersonalDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 8px 0 24px 0;
`;

export const SocialNetworkContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 8px 48px;
  width: 100%;
  max-width: 1000px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 8px;
  border-radius: 8px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
`;

export const BoxSocialNetworkField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentBoxSocialNetworkField = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;
