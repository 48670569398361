import React, { useState, useEffect } from "react";
import { ImNewspaper } from "react-icons/im";
import Marquee from "react-fast-marquee";
import { Alert } from "antd";
import * as S from "./styles";
import theme from "styles/theme";

interface Article {
  id: number;
  tipo: string;
  titulo: string;
  introducao: string;
  data_publicacao: string;
  link: string;
  destaque: boolean;
  editorias: string;
}

const IBGE_API_URL =
  "https://servicodados.ibge.gov.br/api/v3/noticias/?qtd=250";

const NewsMarquee = () => {
  const [news, setNews] = useState<Article[]>([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(IBGE_API_URL);
        const json = await response.json();
        setNews(json.items);
      } catch (error) {
        console.error("Erro ao buscar notícias:", error);
      }
    };

    fetchNews();
  }, []);

  return (
    <Alert
      icon={<S.LabelNews>Últimas Notícias:</S.LabelNews>}
      banner
      style={{
        height: "3rem",
        background: `${theme.COLORS.BACKGROUND_SECONDARY}`,
        alignItems: "center",
        border: `1px solid ${theme.COLORS.ERROR}`,
      }}
      message={
        <Marquee pauseOnHover gradient={false} speed={100}>
          {news.length > 0 ? (
            news.map((article) => (
              <div key={article.id}>
                <a
                  href={article.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: `${theme.COLORS.TEXT_PRIMARY} ` }}
                >
                  <div
                    style={{
                      marginLeft: "12px",
                      alignItems: "center",
                      display: "flex",
                      gap: "12px",
                    }}
                  >
                    {article.titulo}. <ImNewspaper size="16px" />
                  </div>
                </a>
              </div>
            ))
          ) : (
            <p>Carregando notícias...</p>
          )}
        </Marquee>
      }
    />
  );
};

export default NewsMarquee;
