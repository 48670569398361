import React, { useEffect, useState } from "react";
import PlenaryRoomImage from "assets/images/rooms/PlenaryRoom.png";
import PersonalDevelopmentImage from "assets/images/rooms/PersonalDevelopment.png";
import SalesAndExpansionImage from "assets/images/rooms/SalesAndExpansion.png";
import CRMImage from "assets/images/rooms/CRM.png";
import TechnologyImage from "assets/images/rooms/Technology.png";
import PeopleManagementImage from "assets/images/rooms/PeopleManagement.png";
import CLevelImage from "assets/images/rooms/CLevels.png";
import TabsCustom from "componentsNew/Tabs";
import Spin from "componentsNew/Spin";
import MeetingRoom from "pages/MeetingRoom";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidates } from "hooks/useCandidates";
import * as S from "./styles";
import { Image } from "antd";
import SponsorImage from "assets/images/rooms/sponsorship.png";

const backgroundImages: Record<string, string> = {
  tab_thematic_plenary: PlenaryRoomImage,
  tab_thematic_room_personal_development: PersonalDevelopmentImage,
  tab_thematic_room_salesAndExpansion: SalesAndExpansionImage,
  tab_thematic_room_crm: CRMImage,
  tab_thematic_room_technology: TechnologyImage,
  tab_thematic_room_personal_management: PeopleManagementImage,
  tab_thematic_room_cLevel: CLevelImage,
};

const labels = [
  {
    label: "Plenária",
    sub_label: "",
    key: "tab_thematic_plenary",
  },
  {
    label: "CEOS/C-level",
    sub_label: "",
    key: "tab_thematic_room_cLevel",
  },
  {
    label: "Desenvolvimento",
    sub_label: "Pessoal",
    key: "tab_thematic_room_personal_development",
  },
  {
    label: "Gestão de pessoas",
    sub_label: "",
    key: "tab_thematic_room_personal_management",
  },
  {
    label: "Vendas e Expansão",
    sub_label: "",
    key: "tab_thematic_room_salesAndExpansion",
  },
  {
    label: "Verificação de Progresso (CRM)",
    sub_label: "",
    key: "tab_thematic_room_crm",
  },
  {
    label: "Tecnologia",
    sub_label: "",
    key: "tab_thematic_room_technology",
  },
];

const content: React.ReactNode[] = [
  <MeetingRoom roomName="Plenária" tagMeeting="plenaryRoom" position={0} />,
  <MeetingRoom roomName="CEOS/C-level" tagMeeting="cLevel" position={1} />,
  <MeetingRoom
    roomName="Desenvolvimento Pessoal "
    tagMeeting="personalDevelopment"
    position={2}
  />,
  <MeetingRoom
    roomName="Gestão de pessoas"
    tagMeeting="peopleManagement"
    position={3}
  />,
  <MeetingRoom
    roomName="Vendas e Expansão"
    tagMeeting="salesAndExpansion"
    position={4}
  />,
  <MeetingRoom
    roomName="Verificação de Progresso (CRM)"
    tagMeeting="crm"
    position={5}
  />,
  <MeetingRoom roomName="Tecnologia" tagMeeting="technology" position={6} />,
];

export const ThematicGroups = () => {
  const [activeTab, setActiveTab] = React.useState("tab_thematic_plenary");
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now()); //Key for PBIDash

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  const backgroundImage =
    backgroundImages[activeTab as keyof typeof backgroundImages];

  return (
    <S.Wrapper backgroundImage={backgroundImage}>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content.map((comp, index) =>
            React.cloneElement(comp as React.ReactElement, {
              key: `${filterKey}-${index}`,
            }),
          )}
          onChange={(key) => changePoliticalTab(key)}
        />
        {
          activeTab === "tab_thematic_room_technology" &&
          <S.ImgContent>
            <Image width={350} src={SponsorImage} />
          </S.ImgContent>
        }
      </S.Content>
    </S.Wrapper>
  );
};
