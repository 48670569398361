import React, { useState } from "react";
import TitleFormComponent from "../TitleForm";
import Text from "componentsNew/Text";
import Button from "componentsNew/Button";
import Modal from "componentsNew/Modal";
import * as S from "./styles";

interface ITermComponent {
  title?: string;
  subtitle?: string;
  description?: string;
  content?: React.ReactNode;
  version?: string;
}

const TermComponent = ({
  title = "",
  subtitle = "",
  description = "",
  content = "",
  version = "v.1.0",
}: ITermComponent) => {
  const [viewModal, setViewModal] = useState(false);

  const handleOpenModal = () => {
    setViewModal(true);
  };

  const handleCloseModal = () => {
    setViewModal(false);
  };

  return (
    <>
      <S.Wrapper>
        <TitleFormComponent title={title} />
        <S.Container>
          <S.TextContainer>
            <Text size="medium" bold>
              {subtitle}
            </Text>
            <S.Description>{description}</S.Description>
          </S.TextContainer>
          <Button onClick={handleOpenModal}>Abrir Termo ({version})</Button>
        </S.Container>
      </S.Wrapper>
      {viewModal && (
        <S.ModalContainer>
          <Modal
            title={subtitle}
            closeModal={handleCloseModal}
            primaryTextButton="Aceitar"
            secondaryTextButton="Recusar"
            primaryButtonDisabled={false}
            onPrimaryClick={() => console.log("ACCEPTED TERMS")}
            onSecondaryClick={handleCloseModal}
          >
            <Text size="xSmall">{content}</Text>
          </Modal>
        </S.ModalContainer>
      )}
    </>
  );
};

export default TermComponent;
