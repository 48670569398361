import usePlanType from "pages/SettingsProfile/hooks/usePlanType";
import { useUser } from "global/UserContext";
import { useEffect, useState } from "react";
import { apiAdvisor } from "pages/SettingsProfile/api/apiService";

const useMeetingDetails = () => {
  const { user } = useUser();
  const planType = usePlanType();
  const [meetingDetails, setMeetingDetails] = useState(null);

  useEffect(() => {
    const fetchMeetingDetails = async () => {
      try {
        let idToUse = user?.person_id;
        let endpoint = "";

        if (planType !== "Advisor") {
          endpoint = `/advisor/v1/meeting/next?id_user=${idToUse}&page=1&size=50`;
        } else {
          const advisorResponse = await apiAdvisor.get(
            `/advisor/v1/advisors/by-user-id/${idToUse}`,
          );

          idToUse = advisorResponse.data.id;
          endpoint = `/advisor/v1/meeting/next?id_advisor=${idToUse}&page=1&size=50`;
        }

        const response = await apiAdvisor.get(endpoint);

        setMeetingDetails(response.data.items[0]);
      } catch (error) {
        console.error("Erro ao buscar os detalhes da reunião:", error);
      }
    };

    fetchMeetingDetails();
  }, [user, planType]);

  return meetingDetails;
};

export default useMeetingDetails;
