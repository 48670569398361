import styled from "styled-components";
import "styles/global";

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 1190px) {
    max-width: 1200px;
    min-width: 1024px;
  }
`;

export const OptionsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  min-height: 200px;
  flex-direction: column;
`;
