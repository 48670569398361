import styled from "styled-components";
import theme from "styles/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
`;

export const Description = styled.div`
  display: flex;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${theme.COLORS.GRAY_50};
`;

export const Content = styled.div`
  display: flex;
  color: ${theme.COLORS.TEXT_PRIMARY};
`;

export const ModalContainer = styled.div`
  width: 300px;
`;
