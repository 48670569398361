import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LogoMark from "assets/images/logo/logo-prime-club-white-slogan.png";
import ImageMain from "assets/images/login/logo-rainmakers.png";
import Spin from "componentsNew/Spin";
import Text from "componentsNew/Text";
import Button from "componentsNew/Button";
import useLogin from "./useLogin";
import theme from "styles/theme";
import ChangePasswordModal from "components/ChangePasswordModal";
import * as S from "./styles";

export const Login: React.FC = () => {
  const {
    control,
    errors,
    loading,
    handleSubmit,
    onSubmit,
    showChangePasswordModal,
    session,
    email,
    setShowChangePasswordModal,
  } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRememberMe(event.target.checked);
  };

  const navigateToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const navigateToRegister = () => {
    navigate("/register");
  };

  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Card>
            <S.Image src={ImageMain} />
          </S.Card>

          <S.FormContainer>
            <S.ImageLogoMark src={LogoMark} alt="LogoMark Rainmakers" />

            <S.StyledForm onSubmit={handleSubmit(onSubmit)}>
              <Text size="xSmall" color="gray" className="text-form">
                Entre com e-mail e senha
              </Text>
              <S.InputsContainer>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="E-mail"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Senha"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </S.InputsContainer>

              <S.RememberForgotContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: theme.COLORS.PRIMARY,
                        "&.Mui-checked": {
                          color: theme.COLORS.PRIMARY,
                        },
                      }}
                      size="medium"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                  }
                  label="Lembrar-me"
                  style={{ color: theme.COLORS.TEXT_PRIMARY }}
                />

                <Button
                  type="button"
                  title="Esqueceu sua senha?"
                  size="medium"
                  variant="minimal"
                  onClick={navigateToForgotPassword}
                >
                  Esqueceu sua senha?
                </Button>
              </S.RememberForgotContainer>
              <Button
                type="submit"
                variant="primary"
                color="primary"
                fullWidth
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
              >
                {loading ? "Carregando..." : "Entrar"}
              </Button>
              <S.Separator>
                <span>Não tem uma conta?</span>
              </S.Separator>
              <Button
                type="button"
                title="Registrar-se"
                size="medium"
                variant="primary"
                onClick={navigateToRegister}
                style={{
                  width: "10rem",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                Registrar-se
              </Button>
            </S.StyledForm>
          </S.FormContainer>

          {loading && <Spin size="large" fullscreen />}
        </S.Content>

        {showChangePasswordModal && (
          <ChangePasswordModal
            open={showChangePasswordModal}
            onClose={() => setShowChangePasswordModal(false)}
            session={session || ""}
            email={email}
          />
        )}
      </S.Container>
    </S.Background>
  );
};
