import React, { useState } from "react";
import Header from "components/Header";
import NewsMarquee from "componentsNew/NewsMarquee";
import Helper from "componentsNew/Helper";
import SideBar from "components/SideBar";
import UserProfileCard from "components/UserProfileCard";
import ModalNotAffiliations from "componentsNew/ModalNotAffiliations";
import { useSidBar } from "hooks/useSideBar";
import { useUser } from "global/UserContext";
import { useTab } from "global/TabContext";
import * as S from "./styles";

interface ILayout {
  children: React.ReactNode | React.ReactNode[];
}

const Layout = ({ children }: ILayout) => {
  const { open } = useSidBar();
  const { setUser } = useUser();
  const { activeTabContext } = useTab();
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setUser(null);
  };

  const isVisibleCard = activeTabContext === "tab_cockpit";

  return (
    <S.Container>
      <SideBar />
      <Header />
      {isVisibleCard ? (
        <>
          <S.NewsContainer>
            <NewsMarquee />
          </S.NewsContainer>

          <S.Box>
            <S.UserCardContainer>
              <UserProfileCard />
            </S.UserCardContainer>

            <S.Wrapper open={open}>
              <S.Content>{children}</S.Content>
            </S.Wrapper>
          </S.Box>
        </>
      ) : (
        <S.Wrapper open={open}>
          <S.Content>{children}</S.Content>
        </S.Wrapper>
      )}
      <Helper />
      {showModal && <ModalNotAffiliations closeModal={closeModal} />}
    </S.Container>
  );
};

export default Layout;
