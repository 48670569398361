import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import Modal from "componentsNew/Modal";
import { useInvites } from "pages/UserProfile/useInvitesHook";
import { useUser } from "global/UserContext";
import * as S from "../styles";

interface Form {
  id?: string
  invitee_first_name: string
  invitee_last_name: string
  invitee_cell_phone: string
  invitee_email: string
  invite_status: string
}

interface ModalNovaIndicacaoProps {
  handleCloseModal: () => void;
  initialFormValues: Form
}

const ModalNovaIndicacao: React.FC<ModalNovaIndicacaoProps> = (props) => {
  const { user } = useUser();

  const [formValues, setFormValues] = useState(props.initialFormValues);

  const userId = user?.id;
  const { submitInvite, updateInvite } = useInvites({ userId });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleSubmit = async (formValues: any) => {
    try {
      await submitInvite(formValues);
      props.handleCloseModal(); // Close modal after submission
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdate = async (formValues: any) => {
    try {
      await updateInvite(formValues);
      props.handleCloseModal(); // Close modal after submission
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <S.ModalContainer>
      <Modal
        title={"NOVA INDICAÇÃO"}
        closeModal={props.handleCloseModal}
        primaryTextButton={formValues.id ? "Editar Convite" : "Enviar Convite"}
        secondaryTextButton="Cancelar"
        primaryButtonDisabled={false}
        onPrimaryClick={() => formValues.id ? handleUpdate(formValues) : handleSubmit(formValues)}
        onSecondaryClick={props.handleCloseModal}
      >
        <S.FormContainer>
          <form onSubmit={() => handleSubmit(formValues)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="invitee_first_name"
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  value={formValues.invitee_first_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="invitee_last_name"
                  label="Sobrenome do convidado"
                  variant="outlined"
                  fullWidth
                  value={formValues.invitee_last_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="invitee_cell_phone"
                  label="Telefone do convidado"
                  variant="outlined"
                  fullWidth
                  value={formValues.invitee_cell_phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="invitee_email"
                  label="E-mail do convidado"
                  variant="outlined"
                  fullWidth
                  value={formValues.invitee_email}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </form>
        </S.FormContainer>
      </Modal>
    </S.ModalContainer>
  );
};

export default ModalNovaIndicacao;
