import styled from "styled-components";
import theme from "styles/theme";

interface GridContainerProps {
  isTagsLayout?: boolean;
}

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${theme.COLORS.BACKGROUND_PRIMARY};
  color: ${theme.COLORS.TEXT_PRIMARY};
  margin: 16px 0;
`;

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: ${({ isTagsLayout }) =>
    isTagsLayout ? "1fr" : "repeat(auto-fit, minmax(10rem, 1fr))"};
  @media (min-width: 900px) {
    grid-template-columns: ${({ isTagsLayout }) =>
    isTagsLayout ? "1fr" : "repeat(2, 1fr)"};
  }
  gap: 16px;
  width: 100%;
  max-width: 1000px;
`;

export const Card = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: ${theme.COLORS.TEXT_PRIMARY};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const GroupRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  `;
// background-color: red;

export const CardGroup = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: ${theme.COLORS.TEXT_PRIMARY};
  width: 100%;
  min-width: 700px;
  margin-bottom: 16px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
`;

export const CardTags = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: ${theme.COLORS.TEXT_PRIMARY};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  flex-wrap: wrap;
`;

/* export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
`; */

export const StyledFormControlLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;

  .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 0px 4px 0px 0px;
  }

  label {
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Specify = styled.div`
  display: flex;
  align-items: end;
  justify-content: start;
  margin: 16px 8px;
`;

export const Tag = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  color: ${theme.COLORS.TEXT_PRIMARY};
  padding: 6px 12px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  margin: 4px;
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin: 12px 0;
`;

export const Tags = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 32px;
  color: ${theme.COLORS.BACKGROUND_PRIMARY};
  background-color: ${theme.COLORS.PRIMARY};
  margin-bottom: 4px;
`;

export const TextFieldStyle = {
  marginBottom: "8px",
  width: "100%",
};

export const ButtonPlusStyle = {
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.8rem",
  maxWidth: "75px",
  borderRadius: "32px",
  color: `${theme.COLORS.BLACK_1}`,
  borderColor: `${theme.COLORS.BLACK}`,
  backgroundColor: `${theme.COLORS.PRIMARY}`,
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
    opacity: 0.7,
  },
};

export const ButtonEditStyle = {
  fontSize: "0.85rem",
  maxWidth: "75px",
  borderRadius: "32px",
  color: `${theme.COLORS.PRIMARY}`,
  backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
    opacity: 0.6,
  },
};


export const TagsChipsContainer = styled.div`
  .MuiChip-root {
    background-color: ${theme.COLORS.PRIMARY};
    color: ${theme.COLORS.BLACK_1};

    .MuiChip-deleteIcon {
    color: ${theme.COLORS.BLACK_1};
    }
  }
`