import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { apiVox } from "services/apiService";
import { useToast } from "hooks/useToast";
import { useUser } from "global/UserContext";
import ChangePasswordModal from "components/ChangePasswordModal";

const loginSchema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  password: yup
    .string()
    .required("Senha é obrigatória")
    .min(8, "A senha deve ter no mínimo 8 caracteres"),
});

const useLogin = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const navigate = useNavigate();
  const { setUser, setToken } = useUser();
  const { toastSuccess, toastError, toastInfo } = useToast();
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<string | null>(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [email, setEmail] = useState<string>("");

  const handleLoginSuccess = async (data: any) => {
    const { access_token, user_id } = data;

    setToken(access_token);

    try {
      const userData = await apiVox.get(
        `/orchestrator-policy-role/v2/auth/me/${user_id}`,
      );
      setUser(userData.data);
      toastSuccess("Login realizado com sucesso!");
      navigate("/dashboard/cockpit");
    } catch (error) {
      toastError("Erro ao buscar os dados do usuário.");
    }
  };

  const onSubmit = async (data: { email: string; password: string }) => {
    setLoading(true);
    setEmail(data.email);

    try {
      const response = await apiVox.post(
        "/orchestrator-policy-role/v2/auth/login",
        data,
      );

      if (response.data.status === "new_password_required") {
        setSession(response.data.session);
        toastInfo("É necessário redefinir sua senha.");
        setShowChangePasswordModal(true);
      } else {
        await handleLoginSuccess(response.data);
      }
    } catch (error: any) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 400:
            toastError(
              "Requisição inválida. Verifique os dados e tente novamente.",
            );
            break;
          case 401:
            toastError(
              "Usuário ou senha inválidos. Por favor, tente novamente.",
            );
            break;
          case 403:
            toastError("Acesso negado. Verifique suas credenciais.");
            break;
          case 500:
            toastError("Erro interno do servidor. Tente novamente mais tarde.");
            break;
          default:
            toastError("Ocorreu um erro. Tente novamente.");
        }
      } else {
        toastError("Erro de conexão. Por favor, verifique sua internet.");
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    control,
    errors,
    loading,
    onSubmit,
    handleSubmit,
    showChangePasswordModal,
    session,
    email,
    setShowChangePasswordModal,
  };
};

export default useLogin;
