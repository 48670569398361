import styled from "styled-components";
import theme from "styles/theme";
interface propsContainer {
  open: boolean;
}

export const Container = styled.div<propsContainer>`
  ${({ theme, open }) => `
    width: 8.3rem;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    color: ${theme.COLORS.TEXT_PRIMARY};
    display: flex;
    flex-direction: column;
    z-index: 103;
    width: ${open ? "8.3rem" : "2.75rem"};
    padding-bottom: 0.5rem;
    background-color: ${theme.COLORS.BACKGROUND_PRIMARY};
    transition: width 0.3s;
    justify-content: space-between;
    border-right: 2px solid ${theme.COLORS.BACKGROUND_SECONDARY};
  `}

  .avatar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
    ${({ open }) => `
      margin-bottom: ${open ? "0" : "2.3rem"};
    `}
  }

  .user-name {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    gap: 0;
  }
`;

export const WrapperMenu = styled.div``;

export const ButtonToggle = styled.div<propsContainer>`
  width: 1.29rem;
  height: 1.29rem;
  border-radius: 100%;
  position: fixed;
  top: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;

  ${({ theme, open }) => `
    background: ${theme.COLORS.PRIMARY};
    color:${theme.COLORS.BACKGROUND_SECONDARY};
    left: ${open ? "7.6rem" : "2.2rem"};
  `}

  :hover {
    cursor: pointer;
    transform: scale(1.08);
  }
`;

export const WrapperFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  .user {
    border-top: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY};
    padding-top: 0.5rem;
    padding-left: 0.75rem;
  }

  .plan {
    border-top: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY};
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.PRIMARY};
    padding: 0.5rem 0.75rem;
  }

  button {
    background: none;
    border: none;
    height: 1.87rem;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  img {
    max-width: 7rem;
  }
`;
