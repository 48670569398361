import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { apiVox } from "services/apiService";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "utils/constants";
import { toast } from "react-toastify";

interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  whatsapp_number: string;
  image_url: string;
  user_to_organization: Array<{
    organization: {
      name: string;
      uuid: string;
      plan: {
        name: string;
        uuid: string;
      };
    };
    group_role: {
      name: string;
      uuid: string;
    };
    profile: {
      name: string;
      uuid: string;
    };
  }>;
  user_to_teams: any[];
  social_networks: any[];
  person_id: string;
}

interface UserContextProps {
  user: UserData | null;
  setUser: React.Dispatch<React.SetStateAction<UserData | null>>;
  token: string | null;
  setToken: (token: string) => void;
  logout: () => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [token, setToken] = useState<string | null>(
    () => localStorage.getItem(AUTH_TOKEN) || null,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      apiVox.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [token]);

  const handleSetToken = (newToken: string) => {
    setToken(newToken);
    localStorage.setItem(AUTH_TOKEN, newToken);
    apiVox.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem(AUTH_TOKEN);
    toast.info("Você foi desconectado com sucesso.");
    navigate("/login");
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, token, setToken: handleSetToken, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser deve ser usado dentro de um UserProvider");
  }
  return context;
};
