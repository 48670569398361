import React, { createContext, useContext, useState } from "react";
import { IChildTypes } from "types/childrenTypes";

const SidebarContext = createContext<any>(null);

const SidebarProvider = ({ children }: IChildTypes) => {
  const [open, setOpen] = useState<boolean>(true);
  const [titleMenu, setTitleMenu] = useState<boolean>(true);

  return (
    <SidebarContext.Provider value={{ open, setOpen, setTitleMenu, titleMenu }}>
      {children}
    </SidebarContext.Provider>
  );
};

const useSidBar = () => {
  return useContext(SidebarContext);
};

export { SidebarProvider, useSidBar };
