import styled from "styled-components";
import { BREAKPOINTS } from "styles/spacing";
import theme from "styles/theme";
import "styles/global";

export const Background = styled.div`
  background: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 2rem;
  gap: 2rem;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    gap: 1.5rem;
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 64px;
  max-width: 450px;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Image = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 450px;
`;

export const FormContainer = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  border-radius: 16px;
  padding: 2rem;
  width: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 3rem 0;
  max-height: 44rem;
`;

export const ImageLogoMark = styled.img`
  width: 100%;
  max-width: 300px;
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    position: static;
    width: 28rem;
    height: 4.6rem;
  }
`;

export const StyledForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  .error-message {
    display: flex;
    justify-content: start;
    margin-top: 1rem;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  gap: 2rem;
`;

export const RememberForgotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 4rem 0 1rem 0;

  span {
    padding: 0 10px;
    color: ${theme.COLORS.TEXT_PRIMARY};
  }

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 2px solid ${theme.COLORS.GRAY_10};
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
`;
