import { createGlobalStyle } from "styled-components";
import { BREAKPOINTS } from "./spacing";
import theme from "./theme";

export default createGlobalStyle`
    @import 'tailwindcss/base';
    @import 'tailwindcss/components';
    @import 'tailwindcss/utilities';

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
        font-size: 16px;

        @media (max-width: ${BREAKPOINTS.WIDTH.MD}) , (max-height: ${BREAKPOINTS.HEIGHT.MEDIUM}) {
            font-size: 14px;
        };

        @media (max-width: ${BREAKPOINTS.WIDTH.SM}) , (max-height: ${BREAKPOINTS.HEIGHT.SMALL}) {
            font-size: 12px;
        };

        @media (max-width: ${BREAKPOINTS.WIDTH.XS}) , (max-height: ${BREAKPOINTS.HEIGHT.EXTRA_SMALL}) {
            font-size: 10px;
        };
    }

    body {
        background-color: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
        color: ${({ theme }) => theme.COLORS.ERROR};
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
    }

    a {
        text-decoration: none;
    }

    button {
        font-family: 'Roboto', serif;
        font-size: 1rem;
        outline: none;
    }

    button, a {
        cursor: pointer;
        transition: filter 0.2s;
    }

    button:hover, a:hover {
        filter: brightness(0.9);
    }

    ::-webkit-scrollbar {
        width: 0.7rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${theme.COLORS.GRAY_50};
        border-radius: 10px;
        border: 8px solid transparent;
    }

    #filter-drawer {
        background: ${theme.COLORS.BACKGROUND_PRIMARY} !important;
        .ant-drawer-body {
            padding: 0;
        }
    }

    button.ant-drawer-close {
        color: ${theme.COLORS.WHITE} !important;
    }

    .modal_vox {
        .ant-modal-content {
            padding: 12px 0 ;
        }
    }

    .centered_div-empty {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        p {
            color: ${theme.COLORS.GRAY_10};
            font-size: 12px;
        }
    }

    .cursor-not-allowed {
        cursor: not-allowed;
    }

    .ant-spin-dot i {
        background-color: ${theme.COLORS.PRIMARY} !important;
    }

    .react-tel-input .country-list .country {
        background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
        color: ${theme.COLORS.GRAY_10};
    }

    .react-tel-input .country-list .country:hover {
        background-color:  ${theme.COLORS.GRAY_50} !important;
        color:  ${theme.COLORS.WHITE} !important; 
    }

    .react-tel-input .country-list .country.highlight {
        background-color: ${theme.COLORS.PRIMARY} !important;
        color: ${theme.COLORS.BLACK} !important;
    }

    .react-tel-input .flag-dropdown {
        background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
        border: 1px solid ${theme.COLORS.GRAY_40};
    }

    .react-tel-input .flag-dropdown:hover {
        background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
        border: 1px solid ${theme.COLORS.PRIMARY};
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 1000px ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY} inset !important;
        -webkit-text-fill-color: ${({ theme }) => theme.COLORS.TEXT_PRIMARY} !important;
        transition: background-color 5000s ease-in-out 0s;
    }
`;
