import styled from "styled-components";
import theme from "styles/theme";

export const TitleSession = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  color: ${theme.COLORS.BLACK_1};
  background-color: ${theme.COLORS.PRIMARY};
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 8px;
  border-radius: 8px 8px 0 0;
`;
