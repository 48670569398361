import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@mui/material";
import _ from "lodash";
import theme from "styles/theme";
import { useSocialNetworks } from "./hooks/useSocialNetworks";

interface SocialNetworkFieldProps {
  socialNetworkType: string;
  placeholder: string;
}

const SocialNetworkField: React.FC<SocialNetworkFieldProps> = ({
  socialNetworkType,
  placeholder,
}) => {
  const { upsertSocialNetwork, getUsernameByType, loading } =
    useSocialNetworks();
  const existingNetwork = getUsernameByType(socialNetworkType);
  const [username, setUsername] = useState(
    existingNetwork?.social_network_username || "",
  );

  const debouncedUpdate = _.debounce((value: string) => {
    if (value.trim() === "") {
      if (existingNetwork) {
        upsertSocialNetwork({
          ...existingNetwork,
          social_network_username: "",
        });
      }
    } else {
      upsertSocialNetwork({
        social_network_type: socialNetworkType,
        social_network_username: value,
      });
    }
  }, 3000);

  useEffect(() => {
    debouncedUpdate(username);
    return () => {
      debouncedUpdate.cancel();
    };
  }, [username]);

  useEffect(() => {
    setUsername(existingNetwork?.social_network_username || "");
  }, [existingNetwork]);

  return (
    <div
      style={{
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <TextField
        variant="outlined"
        label={placeholder}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
      />
      {loading && (
        <CircularProgress size={24} style={{ color: theme.COLORS.PRIMARY }} />
      )}
    </div>
  );
};

export default SocialNetworkField;
