import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ImageMain from "assets/images/login/logo-rainmakers.png";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmForgotPasswordModal from "components/ConfirmForgotPasswordModal";
import Button from "componentsNew/Button";
import Text from "componentsNew/Text";
import * as yup from "yup";
import { useToast } from "hooks/useToast";
import { apiVox } from "services/apiService";
import { Modal } from "antd";
import * as S from "./styles";

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
});

export const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const { toastSuccess, toastError } = useToast();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmit = async (data: { email: string }) => {
    setLoading(true);
    setEmail(data.email);

    try {
      const response = await apiVox.post(
        "/orchestrator-policy-role/v2/auth/forgot_password",
        {
          email: data.email,
        },
      );

      if (response.data.status === "success") {
        setIsSuccess(true);
        toastSuccess("E-mail enviado com sucesso! Verifique seu e-mail.");
        setOpenConfirmModal(true);
      }
    } catch (error) {
      setIsSuccess(false);
      toastError("Erro ao enviar o e-mail. Por favor, tente novamente.");
      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    if (isSuccess) {
      navigate("/login");
    }
  };

  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Card>
            <S.Image src={ImageMain} />
          </S.Card>
          <S.Form onSubmit={handleSubmit(onSubmit)} className="form-container">
            <S.Separator>
              <h2>Deseja redefinir sua senha?</h2>
            </S.Separator>

            <Text size="xSmall" className="text-instructions">
              Informe o e-mail para redefinição de senha
            </Text>

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            <Button
              type="submit"
              title="Redefinir senha"
              size="medium"
              variant="primary"
              fullWidth
              disabled={loading}
              style={{
                margin: "1rem 0",
              }}
            >
              {loading ? "Carregando..." : "Redefinir senha"}
            </Button>

            <Button
              type="button"
              title="Voltar"
              size="medium"
              variant="minimal"
              onClick={() => navigate("/login")}
            >
              Voltar
            </Button>

            <Text size="xSmall" color="gray" className="support-text">
              Não conseguiu redefinir sua senha?{" "}
              <a href="/support">Clique aqui</a> e entre em contato com nosso
              suporte.
            </Text>
          </S.Form>
        </S.Content>

        <Modal
          open={openModal}
          onCancel={handleModalClose}
          closable
          maskClosable
          footer={null}
          width={400}
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {isSuccess ? (
            <>
              <Text size="large" bold>
                CONFIRMAÇÃO
              </Text>
              <S.Line />
              <S.ModalContent>
                <Text size="xSmall">
                  Você recebeu um email contendo um código de confirmação.
                  Insira o código no campo abaixo para continuar a redefinição.
                </Text>
              </S.ModalContent>
            </>
          ) : (
            <>
              <Text size="large" bold>
                ERRO
              </Text>
              <S.Line />
              <S.ModalContent>
                <Text size="medium">
                  Erro ao enviar o e-mail. Por favor, tente novamente.
                </Text>
                <Button
                  onClick={handleModalClose}
                  variant="primary"
                  color="primary"
                  fullWidth
                >
                  Fechar
                </Button>
              </S.ModalContent>
            </>
          )}
        </Modal>

        {openConfirmModal && (
          <ConfirmForgotPasswordModal
            open={openConfirmModal}
            onClose={() => setOpenConfirmModal(false)}
            email={email}
          />
        )}
      </S.Container>
    </S.Background>
  );
};

export default ForgotPassword;
