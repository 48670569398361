import styled from "styled-components";
import { BREAKPOINTS } from "styles/spacing";
import theme from "styles/theme";

export const Background = styled.div`
  background: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .line {
    border-bottom: 1px solid ${theme.COLORS.GRAY_10};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 2rem;
  gap: 2rem;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    gap: 1.5rem;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 64px;
  max-width: 450px;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Image = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 450px;
`;
export const Form = styled.form`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  border-radius: 16px;
  padding: 2rem;
  width: 36rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 48rem;

  h2 {
    color: ${theme.COLORS.TEXT_PRIMARY};
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 28.13px;
    text-align: center;
  }

  .MuiFormHelperText-root {
    color: ${theme.COLORS.RED_1};
  }
`;

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const NameSurnameContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  > div {
    flex: 1;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.COLORS.GRAY_10};
  margin-bottom: 1rem;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 100px;
  gap: 2rem;
`;
