import React from "react";
import { SidebarProvider } from "hooks/useSideBar";
import { MenuTitleProvider } from "hooks/useMenuTitleContext";
import { FilterGlobalProvider } from "hooks/useFilterGlobal";
import { FilterVoxProvider } from "hooks/useFilterVox";
import { CandidatesProvider } from "hooks/useCandidates";
import { TrajectoryTimeLineProvider } from "global/TrajectoryTimeLineContext";
import { TrajectoryValidVotesProvider } from "global/TrajectoryValidVotesContext";
import { TrajectoryActivitiesProvider } from "global/TrajectoryActivitiesContext";
import { TrajectoryExpensesProvider } from "global/TrajectoryExpensesContext";
import { TrajectoryDonationsProvider } from "global/TrajectoryDonationsContext";
import { HorizontalSubmenuProvider } from "global/HorizontalMenuContext";
import { DiagnosisFilterProvider } from "global/DiagnosisFilterContext";
import { MenuProvider } from "global/MenuContext";
import { UserProvider } from "global/UserContext";
import { UserPersonProvider } from "global/UsePersonContext";
import { ReportProvider } from "global/ReportContext";
import { TabProvider } from "global/TabContext";
import { IChildTypes } from "types/childrenTypes";
import { CandidateDiagnosticFilterProvider } from "global/CandidateDiagnosticFilterContext";
import { DrawerProvider } from "global/DrawerContext";

const ProviderContext = ({ children }: IChildTypes) => {
  return (
    <MenuTitleProvider>
      <SidebarProvider>
        <TabProvider>
          <UserProvider>
            <UserPersonProvider>
              <CandidatesProvider>
                <ReportProvider>
                  <CandidateDiagnosticFilterProvider>
                    <DiagnosisFilterProvider>
                      <DrawerProvider>
                        <FilterVoxProvider>
                          <FilterGlobalProvider>
                            <TrajectoryTimeLineProvider>
                              <TrajectoryValidVotesProvider>
                                <TrajectoryActivitiesProvider>
                                  <TrajectoryExpensesProvider>
                                    <TrajectoryDonationsProvider>
                                      <HorizontalSubmenuProvider>
                                        <MenuProvider>{children}</MenuProvider>
                                      </HorizontalSubmenuProvider>
                                    </TrajectoryDonationsProvider>
                                  </TrajectoryExpensesProvider>
                                </TrajectoryActivitiesProvider>
                              </TrajectoryValidVotesProvider>
                            </TrajectoryTimeLineProvider>
                          </FilterGlobalProvider>
                        </FilterVoxProvider>
                      </DrawerProvider>
                    </DiagnosisFilterProvider>
                  </CandidateDiagnosticFilterProvider>
                </ReportProvider>
              </CandidatesProvider>
            </UserPersonProvider>
          </UserProvider>
        </TabProvider>
      </SidebarProvider>
    </MenuTitleProvider>
  );
};

export default ProviderContext;
