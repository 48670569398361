import styled from "styled-components";

interface propsContainer {
  open: boolean;
  isActive: boolean;
  isSelected: boolean;
}

export const Container = styled.div<propsContainer>`
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  background: ${({ theme, isSelected }) =>
    isSelected && theme.COLORS.BACKGROUND_SECONDARY};
  opacity: ${({ isActive }) => !isActive && 0.5};

  :hover {
    background: ${({ theme, isActive, isSelected }) =>
      isSelected ? theme.COLORS.BLACK : theme.COLORS.BLACK_6};
    cursor: ${({ isActive }) => (isActive ? "pointer" : "not-allowed")};
  }
`;

export const Wrapper = styled.div`
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Bar = styled.div`
  width: 0.25rem;
  height: 100%;
  background: ${({ theme }) => theme.COLORS.PRIMARY};
`;
