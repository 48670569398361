import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, Upload, Image } from "antd";
import AvatarDefault from "assets/avatar-default.png";
import { getSocialNetworkIcon } from "./utils/socialNetworkIcons";
import Text from "componentsNew/Text";
import { useTab } from "global/TabContext";
import { useUser } from "global/UserContext";
import useProfileImage from "pages/SettingsProfile/hooks/useProfileImage";
import SelfAssessmentsComponents from "./components/SelfAssessmentsComponents";
import { useUserSocialNetworks } from "./hooks/useUserSocialNetworks";
import theme from "styles/theme";
import * as S from "./styles";

const UserProfileCard: React.FC = () => {
  const { activeTabContext } = useTab();
  const { user } = useUser();

  const [userFirstName, setUserFirstName] = useState(user?.first_name);
  const [userLastName, setUserLastName] = useState(user?.last_name);
  const progress = 82;
  const profileLevel = "OURO";

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);

  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { profileImage, updateProfileImage, deleteProfileImage, loading } =
    useProfileImage(user?.id || "");

  const {
    socialNetworks,
    loading: socialLoading,
    error: socialError,
  } = useUserSocialNetworks();

  useEffect(() => {
    if (user) {
      setUserFirstName(user?.first_name);
      setUserLastName(user?.last_name);
    }
  }, [user]);

  const handleUpload = (info: any) => {
    const file = info.file;
    if (!file) {
      toast.error("Nenhum arquivo foi selecionado.");
      return;
    }
    if (!file.type || !file.type.startsWith("image/")) {
      toast.error("Por favor, envie um arquivo de imagem válido.");
      return;
    }

    setSelectedFile(file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleConfirmUpload = () => {
    if (selectedFile) {
      updateProfileImage(selectedFile);
      setPreviewImage(null);
      setSelectedFile(null);
      toast.success("Foto de perfil atualizada com sucesso!");
    } else {
      toast.error("Nenhum arquivo selecionado.");
    }
  };

  const handleCancelUpload = () => {
    setPreviewImage(null);
    setSelectedFile(null);
    setIsModalVisible(false);
  };

  const handleRemoveImage = () => {
    deleteProfileImage();
    toast.success("Foto de perfil removida com sucesso!");
    setIsRemoveModalVisible(false);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalVisible(false);
  };

  const isCockpitTab = activeTabContext === "tab_cockpit";

  const renderAvatarOrImage = () => {
    if (previewImage) {
      return (
        <>
          <Text size="medium">Pré-visualização:</Text>
          <img
            src={previewImage}
            alt="Preview"
            style={{ width: 120, height: 120, borderRadius: "50%" }}
          />
          <div style={{ marginTop: 16 }}>
            <Button
              onClick={handleCancelUpload}
              style={{
                width: "120px",
                margin: 8,
                color: `${theme.COLORS.PRIMARY}`,
                backgroundColor: "transparent",
                border: `1px solid ${theme.COLORS.PRIMARY}`,
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleConfirmUpload}
              loading={loading}
              style={{
                width: "120px",
                margin: 8,
                color: `${theme.COLORS.BLACK}`,
                backgroundColor: `${theme.COLORS.PRIMARY}`,
                border: "none",
              }}
            >
              Confirmar envio
            </Button>
          </div>
        </>
      );
    }

    if (profileImage) {
      return (
        <img
          src={profileImage}
          alt={`${userFirstName} profile picture`}
          style={{ width: 120, height: 120, borderRadius: "50%" }}
        />
      );
    }
    return (
      <S.ProfilePicture
        src={AvatarDefault}
        alt={`${userFirstName} profile picture`}
      />
    );
  };

  return (
    <S.CardContainer>
      <S.ProfilePictureWrapper>{renderAvatarOrImage()}</S.ProfilePictureWrapper>
      {!isCockpitTab && (
        <Button
          type="link"
          style={S.ButtonChangePictureStyle}
          onClick={() => setIsModalVisible(true)}
        >
          Alterar foto de perfil
        </Button>
      )}
      <Text size="large" bold className="user-name">
        {userFirstName} {userLastName}
      </Text>

      {!isCockpitTab && (
        <S.IconsContainer>
          {socialLoading && <Text>Carregando redes sociais...</Text>}
          {socialError && (
            <Text color="red">
              Erro ao carregar redes sociais: {socialError}
            </Text>
          )}

          {!socialLoading &&
            !socialError &&
            socialNetworks
              .filter((network) => network.social_network_username)
              .map((network) => (
                <a
                  key={network.id}
                  href={`https://${network.social_network_type.toLowerCase()}.com/${network.social_network_username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getSocialNetworkIcon(network.social_network_type)}
                </a>
              ))}
        </S.IconsContainer>
      )}

      {activeTabContext === "tab_user_profile_self_assessment" && (
        <SelfAssessmentsComponents />
      )}

      <Modal
        open={isModalVisible}
        onCancel={handleCloseModal}
        closable
        maskClosable
        footer={null}
        title="ALTERAR FOTO DE PERFIL"
        width={400}
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {previewImage ? (
          <S.UpdateContainer>
            <Text size="medium" bold>
              PRÉ-VISUALIZAÇÃO:
            </Text>
            <Image
              src={previewImage}
              alt="Preview Image"
              style={{ width: 150, height: 150, borderRadius: "50%" }}
            />
            <div style={{ marginTop: 16 }}>
              <Button
                onClick={handleCancelUpload}
                style={{
                  width: "120px",
                  margin: 8,
                  color: `${theme.COLORS.PRIMARY}`,
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.COLORS.PRIMARY}`,
                }}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                onClick={handleConfirmUpload}
                loading={loading}
                style={{
                  width: "120px",
                  margin: 8,
                  color: "#000000",
                  backgroundColor: `${theme.COLORS.PRIMARY}`,
                  border: "none",
                }}
              >
                Confirmar envio
              </Button>
            </div>
          </S.UpdateContainer>
        ) : (
          <div style={{ textAlign: "center", justifyContent: "center" }}>
            <S.UpdateContainer>
              {profileImage && (
                <Image
                  src={profileImage}
                  alt="Preview Image"
                  style={{ width: 150, height: 150, borderRadius: "50%" }}
                />
              )}
              <S.ButtonsContainer>
                <Upload
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={handleUpload}
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.webp,.tiff,.svg"
                >
                  <Button
                    type="link"
                    block
                    style={S.PublishButtonLinkStyle}
                    loading={loading}
                  >
                    Carregar nova foto de perfil
                  </Button>
                </Upload>
                <Button
                  danger
                  type="link"
                  style={S.ButtonLinkDangerStyle}
                  onClick={() => setIsRemoveModalVisible(true)}
                >
                  Remover foto de perfil
                </Button>
              </S.ButtonsContainer>
            </S.UpdateContainer>
          </div>
        )}
      </Modal>

      <Modal
        open={isRemoveModalVisible}
        onCancel={handleCloseRemoveModal}
        closable
        maskClosable
        footer={null}
        title="CONFIRMAR REMOÇÃO DE IMAGEM"
        width={400}
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <S.UpdateContainer>
          <Text size="medium" bold color="yellow">
            Deseja realmente remover a foto de perfil?
          </Text>
          {profileImage && (
            <Image
              src={profileImage}
              alt="Profile Image to Remove"
              style={{ width: 150, height: 150, borderRadius: "50%" }}
            />
          )}
          <div style={{ marginTop: 16 }}>
            <Button
              onClick={handleCloseRemoveModal}
              style={{
                margin: 8,
                color: `${theme.COLORS.PRIMARY}`,
                backgroundColor: "transparent",
                border: `1px solid ${theme.COLORS.PRIMARY}`,
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              block
              danger
              onClick={handleRemoveImage}
              loading={loading}
              style={{
                width: "auto",
                margin: 8,
                color: `${theme.COLORS.WHITE}`,
                backgroundColor: `${theme.COLORS.ERROR}`,
                border: "none",
              }}
            >
              Confirmar remoção
            </Button>
          </div>
        </S.UpdateContainer>
      </Modal>

      <ToastContainer position="top-right" autoClose={5000} />
    </S.CardContainer>
  );
};

export default UserProfileCard;
