"use client";

import React from "react";
import { useUser } from "global/UserContext";
import { useIframeCommunication } from "../../../hooks/useIframeCommunication";
import { apiAdvisorsNext } from "microfrontends/config/api";

const IframeContainer: React.FC = () => {
  const { user } = useUser();
  const userId = user?.person_id;
  const iframeUrl = `${apiAdvisorsNext}/advisorplatform/calls/user-calls-to-advisor?userId=${userId}`;

  const { iframeRef, sendMessageToIframe } = useIframeCommunication(iframeUrl);

  return (
    <>
      <div>
        <iframe
          ref={iframeRef}
          src={iframeUrl}
          width="100%"
          height="570px"
          title="Next.js App"
        />
      </div>
    </>
  );
};

export default IframeContainer;
