import React from "react";
import styled, { css } from "styled-components";
import theme from "styles/theme";

interface ITextProps {
  children: React.ReactNode;
  size?: "small" | "xSmall" | "medium" | "large" | "xlarge";
  bold?: boolean;
  color?: "white" | "black" | "gray" | "green" | "red" | "yellow" | "primary";
  secondary?: boolean;
}

const CUSTOM_SIZE = {
  small: css`
    font-size: ${theme.FONT_SIZE.SMALL};
  `,
  xSmall: css`
    font-size: ${theme.FONT_SIZE.X_SMALL};
  `,
  medium: css`
    font-size: ${theme.FONT_SIZE.MEDIUM};
  `,
  large: css`
    font-size: ${theme.FONT_SIZE.LARGE};
  `,
  xlarge: css`
    font-size: ${theme.FONT_SIZE.X_LARGE};
  `,
};

const CUSTOM_COLOR = {
  white: css`
    color: ${theme.COLORS.TEXT_PRIMARY};
  `,
  black: css`
    color: ${theme.COLORS.BLACK};
  `,
  gray: css`
    color: ${theme.COLORS.GRAY_30};
  `,
  green: css`
    color: ${theme.COLORS.GREEN_1};
  `,
  red: css`
    color: ${theme.COLORS.ERROR};
  `,
  yellow: css`
    color: ${theme.COLORS.YELLOW_1};
  `,
  primary: css`
    color: ${theme.COLORS.PRIMARY};
  `,
};

export const ContainerText = styled.p<ITextProps>`
  color: ${theme.COLORS.TEXT_PRIMARY};
  font-weight: ${({ theme, bold }) =>
    bold ? theme.FONT_WEIGHT.BOLD : theme.FONT_WEIGHT.REGULAR};
  ${({ size }) => CUSTOM_SIZE[size || "small"]};
  ${({ color }) => CUSTOM_COLOR[color || "white"]};
  font-family: ${({ theme, secondary }) =>
    !secondary ? theme.FONT_FAMILY.PRIMARY : theme.FONT_FAMILY.SECONDARY};
`;
