import React from "react";
import Text from "componentsNew/Text";
import SocialNetworkField from "components/SocialNetworkField";
import { lowerCase } from "lodash";
import * as S from "./styles";

interface SocialNetworkFieldsProps {
  networkTypes: string[];
}

const SocialNetworkFields: React.FC<SocialNetworkFieldsProps> = ({
  networkTypes,
}) => {
  return (
    <S.SocialNetworkContainer>
      {networkTypes.map((type: string) => (
        <S.BoxSocialNetworkField key={type}>
          <Text size="medium" bold>{`Perfil pessoal do ${type}`}</Text>
          <S.ContentBoxSocialNetworkField>
            <Text
              size="xSmall"
              color="gray"
            >{`www.${lowerCase(type)}.com/`}</Text>
            <SocialNetworkField
              socialNetworkType={type}
              placeholder={`${type}`}
            />
          </S.ContentBoxSocialNetworkField>
        </S.BoxSocialNetworkField>
      ))}
    </S.SocialNetworkContainer>
  );
};

export default SocialNetworkFields;
