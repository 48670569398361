"use client";

import React from "react";
import { apiAdvisorsNext } from "microfrontends/config/api";
import { useUser } from "global/UserContext";
import { useIframeCommunication } from "hooks/useIframeCommunication";
import * as S from "./styles";

interface IframeContainerProps {
  specialty: string;
  buttonText?: string;
  iframeHeight?: string;
}

const IframeContainer: React.FC<IframeContainerProps> = ({ specialty }) => {
  const { user } = useUser();
  const userId = user?.person_id;
  const iframeUrl = `${apiAdvisorsNext}/advisorplatform/advisors/specialtyclient/${specialty}?userId=${userId}`;

  const { iframeRef } = useIframeCommunication(iframeUrl);

  return (
    <S.Container>
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        width="100%"
        title="Next.js App"
      />
    </S.Container>
  );
};

export default IframeContainer;
