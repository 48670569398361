import { useState, useEffect } from "react";
import { apiOrchestrator } from "services/apiService";

interface Invite {
  id: string;
  user_id: string;
  invitee_first_name: string;
  invitee_last_name: string;
  invitee_email: string;
  invitee_cell_phone: string;
  invite_status: "pending" | "accepted" | "declined";
  invited_at: string;
  updated_at: string;
}

interface InvitesResponse {
  items: Invite[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

interface InvitesProps {
  userId: any;
  page?: number;
  size?: number;
  orderBy?: string;
  isDesc?: boolean;
  inviteStatus?: string;
}

interface InviteSubmission {
  invitee_first_name: string;
  invitee_last_name: string;
  invitee_cell_phone: string;
  invitee_email: string;
  invite_status: string;
}
interface InviteUpdate {
  id: string;
  invitee_first_name?: string;
  invitee_last_name?: string;
  invitee_cell_phone?: string;
  invitee_email?: string;
  invite_status?: string;
}

export const useInvites = ({
  userId,
  page = 0,
  size = 10,
  orderBy,
  isDesc,
  inviteStatus,
}: InvitesProps) => {
  const [invites, setInvites] = useState<Invite[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchInvites(userId);
  }, [userId, page, size, orderBy, isDesc, inviteStatus]);

  async function fetchInvites(userId: any) {
    setLoading(true);
    setError(null);
    try {
      const params: { [key: string]: any } = {
        user_id: userId,
        page: page + 1,
        size: size,
      };

      // Only add parameters if they are defined
      if (orderBy) {
        params.order_by = orderBy;
      }
      if (isDesc !== undefined) {
        params.is_desc = isDesc;
      }
      if (inviteStatus) {
        params.invite_status = inviteStatus;
      }
      const resp = await apiOrchestrator.get<InvitesResponse>(
        `/orchestrator-policy-role/v2/invites/`,
        {
          params: params, // Use the dynamically constructed params object
        },
      );
      setInvites(resp.data.items);
      setTotal(resp.data.total);
      // setPages(resp.data.pages); // Store total pages if needed
    } catch (err) {
      console.error(err);
      setError("Failed to fetch invites");
    } finally {
      setLoading(false);
    }
  }

  async function submitInvite(props: InviteSubmission) {
    try {
      await apiOrchestrator.post(
        `/orchestrator-policy-role/v2/invites/${userId}`,
        {
          ...props, // Use the dynamically constructed params object
        },
      );
      await fetchInvites(userId); // Ensure the invites list is fetched right after submission
    } catch (err) {
      console.log(err);
    }
    // e.preventDefault();
    // updatePersonalInfo(formValues);
  }
  
  async function updateInvite(props: InviteUpdate) {
    try {
      await apiOrchestrator.patch(
        `/orchestrator-policy-role/v2/invites/${props.id}`,
        {
          ...props, // Use the dynamically constructed params object
        },
      );
      await fetchInvites(userId); // Ensure the invites list is fetched right after submission
    } catch (err) {
      console.log(err);
    }
    // e.preventDefault();
    // updatePersonalInfo(formValues);
  }

  async function deleteInvite(inviteId: string) {
    try {
      await apiOrchestrator.delete(
        `/orchestrator-policy-role/v2/invites/${inviteId}`,
      );
      // Fetch the updated list of invites after deleting
      await fetchInvites(userId);
    } catch (err) {
      console.error(err);
      setError("Failed to delete invite");
    }
  }

  return {
    invites,
    total,
    loading,
    error,
    submitInvite,
    updateInvite,
    fetchInvites,
    deleteInvite,
  };
};
