import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ROTES } from "utils/constants";
import { PrivateRoute } from "./PrivateRoutes";
import RouteTransition from "./RouteTansition";
import Layout from "componentsNew/Layout";
import { Login } from "pages/Login";
import Logout from "componentsNew/Logout";
import { OnboardingPage } from "pages/Onboarding";
import { Cockpit } from "pages/Cockpit";
import { CommunityProfile } from "pages/CommunityProfile";
import { IntelligenceNetworking } from "pages/IntelligenceNetworking";
import { ThematicGroups } from "pages/ThematicGroups";
import { WizardElectoralProfile } from "pages/WizardElectoralProfile";
import { Acquisitions } from "pages/Acquisitions";
import { UserProfile } from "pages/UserProfile";
import { SettingsProfile } from "pages/SettingsProfile";
import Register from "pages/Register";
import { ForgotPassword } from "pages/ForgotPassword";



import Page404 from "pages/Errors/404";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<RouteTransition />}>
        <Route path={ROTES.LOGIN} element={<Login />} />
        <Route path={ROTES.ONBOARDING} element={<OnboardingPage />} />
        <Route path={ROTES.REGISTER} element={<Register />} />
        <Route path={ROTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROTES.LOGOUT} element={<Logout />} />

        <Route
          path={ROTES.DASHBOARD}
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route
            index
            path={ROTES.DASHBOARD_COCKPIT_MAIN}
            element={
              <PrivateRoute>
                <Cockpit />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.COMMUNITY_PROFILE}
            element={
              <PrivateRoute>
                <CommunityProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.INTELLIGENCE_NETWORKING}
            element={
              <PrivateRoute>
                <IntelligenceNetworking />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.THEMATIC_GROUPS}
            element={
              <PrivateRoute>
                <ThematicGroups />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.ACQUISITIONS}
            element={
              <PrivateRoute>
                <WizardElectoralProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.ELECTORAL_WIZARD}
            element={
              <PrivateRoute>
                <WizardElectoralProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.USER_PROFILE}
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.SETTINGS_PROFILE}
            element={
              <PrivateRoute>
                <SettingsProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Page404 />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/*" element={<Navigate to={ROTES.LOGIN} />} />
        <Route path="*" element={<Navigate to={ROTES.NOT_FOUND} />} />
        <Route path="/not-found" element={<Page404 />} />
      </Route>
    </Routes>
  );
};
